
/* Attempt to add a program name to the list of program names.
Returns isValid: true if the input is valid or empty, false otherwise.
*/
export default function attemptAddProgramName(
    programNames: string[],
    input: string | undefined,
): { isValid: boolean, newProgramNames?: string[] } {
    const trimmedInput = input?.trim();

    // Prevent adding empty strings, whitespace codes, and duplicates
    if (trimmedInput == null || trimmedInput.length === 0) {
        return { isValid: true };
    } else if (programNames?.includes(trimmedInput)) {
        alert('Invalid program name! Make sure it is not a duplicate.');
        return { isValid: false };
    } else {
        return { isValid: true, newProgramNames: [...programNames, trimmedInput] };
    }
}
