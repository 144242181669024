import { maxDateNotNull, minDateNotNull } from '../../back/common/utils/minMaxUtils.js';


export function clamp(value: number, min: number, max: number): number {
    return Math.min(Math.max(value, min), max);
}

/** If value is null, max date is returned. If both value and max are null, then min is returned (which
 * goes against the idea of null meaning infinity). */
export function clampDate(value: Date | null, min: Date, max: Date | null): Date {
    //Must compare against max value first, so that when value is null, then max is returned
    return maxDateNotNull(minDateNotNull(value, max), min);
}

export function getClampedPeriods<T extends Period>(
    start: Date,
    end: Date | null,
    periods: T[]
): T[] {
    const clampedPeriods: T[] = [];
    periods.forEach((period) => {
        const clampedStart = period.start ? clampDate(start, period.start, period.end) : start;
        const clampedEnd = (period.end ? clampDate(end!, period.start, period.end) : end) as Date;
        if (clampedStart !== clampedEnd) {
            clampedPeriods.push({
                ...period,
                start: new Date(clampedStart),
                end: new Date(clampedEnd)
            });
        }
    });
    return clampedPeriods;
}


interface Period {
    start: Date;
    end: Date | null;
}
