import { useEffect, useRef } from 'react';


export default function useFormKeyboardNavigation() {
    const formRef = useRef<HTMLFormElement>(null);

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (!formRef.current) return;
            navigateOnKey(e, formRef.current);
        };

        const currentForm = formRef.current;
        currentForm?.addEventListener('keydown', handleKeyDown);
        return () => {
            currentForm?.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return formRef;
}


function navigateOnKey(e: KeyboardEvent, form: HTMLFormElement) {
    const direction = getDirection(e);
    if (!direction) return;
    const current = e.target as FocusableElement;

    if (isWrappedByTable(current, form)) {
        focusNextTableRow(current, direction);
    } else {
        focusNext(current, direction);
    }
    e.preventDefault();
}

function getDirection(e: KeyboardEvent): 1 | -1 | null {
    if (e.key === 'ArrowUp') {
        return -1;
    } else if (e.key === 'ArrowDown') {
        return 1;
    } else if (e.key === 'Enter') {
        const target = e.target as HTMLElement;
        const isTextInput = target.tagName === 'INPUT' && (
            (target as HTMLInputElement).type === 'text' || (target as HTMLInputElement).type === 'number'
        );
        if (!isTextInput) return null;
        return e.shiftKey ? -1 : 1;
    } else {
        return null;
    }
}

function isWrappedByTable(element: FocusableElement, form: HTMLFormElement): boolean {
    if (!form.contains(element)) {
        return false;
    }

    let parent: HTMLElement | null = element.parentElement;
    while (parent && parent !== form) {
        if (parent.tagName === 'TABLE') {
            return true;
        }
        parent = parent.parentElement;
    }

    return false;
}

function focusNext(current: FocusableElement, direction: 1 | -1) {
    const form = current.closest('form');
    if (!form) return;

    const inputs = Array.from(form.querySelectorAll(focusableQuery)) as FocusableElement[];
    const currentIndex = inputs.indexOf(current);

    for (let i = currentIndex + direction; i >= 0 && i < inputs.length; i += direction) {
        const nextInput = inputs[i];
        if (nextInput.disabled || nextInput.tabIndex === -1) continue;
        nextInput?.focus();
        break;
    }
}

function focusNextTableRow(current: FocusableElement, direction: 1 | -1) {
    const tbody = current.closest('tbody');
    const currentRow = current.closest('tr');
    const currentCell = current.closest('td');
    if (!tbody || !currentRow || !currentCell) return;

    const rows = Array.from(tbody.children);
    const currentRowIndex = rows.indexOf(currentRow);
    const currentColumnIndex = Array.from(currentRow.children).indexOf(currentCell);

    const nextRowIndex = currentRowIndex + direction;
    if (nextRowIndex < 0 || nextRowIndex >= rows.length) {
        focusNextOutsideTable(current, direction);
        return;
    }

    const cellsInNextRow = Array.from(rows[nextRowIndex].children);
    const candidateCells = [cellsInNextRow[currentColumnIndex], ...cellsInNextRow].filter(Boolean)
    const candidateInputs = candidateCells.flatMap(cell => Array.from(cell.querySelectorAll(focusableQuery))) as FocusableElement[];
    const nextInput = candidateInputs.find(cell => isValidFocusTarget(cell));
    if (nextInput) {
        nextInput.focus();
    } else {
        const closestNextInput = candidateInputs[currentColumnIndex] ?? candidateInputs[0];
        if (closestNextInput) {
            focusNextTableRow(closestNextInput, direction);
        }
    }
}

function focusNextOutsideTable(current: FocusableElement, direction: 1 | -1) {
    const form = current.closest('form');
    if (!form) return;

    const focusableElements = Array.from(form.querySelectorAll(focusableQuery)) as FocusableElement[];
    const currentIndex = focusableElements.indexOf(current);
    if (currentIndex === -1) return;

    // Check for the next focusable element outside the table
    for (let i = currentIndex + direction; i >= 0 && i < focusableElements.length; i += direction) {
        const nextElement = focusableElements[i];
        if (nextElement.disabled || nextElement.tabIndex === -1) continue;
        if (!nextElement.closest('table') || current.closest('table') !== nextElement.closest('table')) {
            nextElement.focus();
            return;
        }
    }
}

function isValidFocusTarget(element: Element): boolean {
    const isFocusableType = element instanceof HTMLInputElement
        || element instanceof HTMLTextAreaElement
        || element instanceof HTMLSelectElement
        || element instanceof HTMLButtonElement;
    return isFocusableType && !element.disabled && element.tabIndex !== -1;
}

type FocusableElement = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | HTMLButtonElement;
const focusableQuery = 'input, textarea, select, button';
