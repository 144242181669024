import { ControlValues } from '../../../shared/services/spc/getControlValues';


export function getCpk(controlValues: ControlValues) {
    //Cpk definition rom https://www.6sigma.us/process-improvement/process-capability-index-cpk/#:~:text=Cpk%20(Process%20Capability%20Index)%3A,or%20midpoint%20of%20the%20specifications
    //Cpk = Minimum of [(Upper Specification Limit – Process Mean) / (3 * Process Standard Deviation),
    //(Process Mean – Lower Specification Limit) / (3 * Process Standard Deviation)]
    const cpk1 = (controlValues.tolerances[1] - controlValues.mean) / (3 * controlValues.stddev);
    const cpk2 = (controlValues.mean - controlValues.tolerances[0]) / (3 * controlValues.stddev);
    return Math.min(cpk1, cpk2);
}
