import { useState } from 'react';
import MeasurementGraphControl from '../../components/measurement-graph/measurementGraphControl';
import MeasurementGraphSettings from '../../components/measurement-graph/measurementGraphSettings';
import MeasurementsGraph from '../../components/measurement-graph/measurementsGraph';
import SigmaHistogram from '../../components/sigma-histogram/sigmaHistogram';
import Overview from './overview';
import PositionDetails from './positionDetails';
import ProtocolPdfDialog from './protocolPdfDialog';
import RangeSelection, { RangeOption } from './rangeSelection';
import SpcChecksTable from './spcChecksTable';
import useEnabledSpcChecks from './useEnabledSpcChecks';
import { useLoadRecords } from './useLoadRecords';
import { useNumIssues } from './useNumIssues';


export default function SpcDetails() {
    const searchParams = new URLSearchParams(window.location.search);
    const orderNumber = searchParams.get('orderNumber') as string;

    const [activePositionIdx, setActivePositionIdx] = useState<number | undefined>(undefined);
    const [selectedRange, setSelectedRange] = useState<RangeOption | null>(null);
    const [showPdfDialog, setShowPdfDialog] = useState(false);
    const [measurementGraphSettings, setMeasurementGraphSettings] = useState<MeasurementGraphSettings>({
        lineVisibilities: { nominal: true, tolerance: true, mean: true, }
    });

    const {
        records,
        protocol,
    } = useLoadRecords({ orderNumber });
    const numLatest = selectedRange?.count ?? records?.filter(it => it.orderNumber === orderNumber).length;

    const { enabledSpcChecks, onSpcCheckChanged } = useEnabledSpcChecks({ orderNumber, protocol });
    const issueCount = useNumIssues({ protocol, records, enabledSpcChecks, numLatest });


    return (
        <div className='measurement-container'>
            <div className='measurement-main-pane' style={{ padding: '12px' }} >

                <button style={{ float: 'right' }} onClick={() => setShowPdfDialog(true)}>
                    View pdf
                </button>

                {(orderNumber && protocol && records && activePositionIdx != null) && <>
                    <MeasurementGraphControl
                        settings={measurementGraphSettings}
                        setSettings={setMeasurementGraphSettings}
                    />

                    <MeasurementsGraph
                        style={{ width: '100%', height: '40%', marginTop: '12px' }}
                        protocol={protocol}
                        prevRecords={records}
                        currentRecord={null}
                        selectedPositionIdx={activePositionIdx}
                        openDialogOnClick={false}
                        settings={measurementGraphSettings}
                        enabledSpcChecks={enabledSpcChecks}
                        maxDataPoints={selectedRange?.count}
                    />

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }} >
                        <SigmaHistogram
                            style={{ width: '600px', height: '300px', marginTop: '12px' }}
                            protocol={protocol}
                            records={records}
                            activePositionIdx={activePositionIdx}
                            numLatest={selectedRange?.count}
                        />

                        <div style={{ width: '300px', display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '24px' }} >
                            <PositionDetails
                                style={{ marginTop: '24px' }}
                                protocol={protocol}
                                records={records}
                                activePositionIdx={activePositionIdx}
                            />

                            <RangeSelection
                                style={{ marginTop: '24px' }}
                                orderNumber={orderNumber}
                                batchRecords={records}
                                selectedRange={selectedRange}
                                setSelectedRange={setSelectedRange}
                            />
                        </div>
                    </div>
                </>}

                {activePositionIdx == null && <div style={{ marginLeft: '40px', marginTop: '20px' }}>
                    Select a position to view details
                </div>}
            </div>

            <div
                className='measurement-side-pane'
                style={{ overflowY: 'auto' }}
            >
                <div style={{ marginTop: '20px', marginBottom: '20px', }}>
                    {(protocol && records) &&
                        <Overview
                            orderNumber={orderNumber}
                            protocol={protocol}
                            batchRecords={records}
                            enabledIssues={issueCount?.enabledIssues}
                            allIssues={issueCount?.allIssues}
                        />
                    }

                    {(protocol && records && enabledSpcChecks) &&
                        <SpcChecksTable
                            style={{ marginTop: '12px' }}
                            protocol={protocol}
                            records={records}
                            activePositionIdx={activePositionIdx}
                            setActivePositionIdx={setActivePositionIdx}
                            enabledSpcChecks={enabledSpcChecks}
                            onEnabledSpcCheckChanged={onSpcCheckChanged}
                            numLatest={numLatest}
                        />
                    }

                    <button
                        style={{ marginTop: '20px' }}
                        onClick={() => window.history.back()}
                    >
                        Close
                    </button>
                </div>
            </div>

            <ProtocolPdfDialog
                protocol={protocol}
                selectedPositionIdx={activePositionIdx}
                show={showPdfDialog}
                onClose={() => setShowPdfDialog(false)}
            />
        </div>
    );
}
