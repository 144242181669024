import { useQuery } from '@tanstack/react-query';
import { StrictMode } from 'react';
import { Outlet, Route, BrowserRouter as Router, Routes, useNavigate } from 'react-router-dom';
import { Flip, ToastContainer } from 'react-toastify';
import { fetchIsAdmin, fetchIsLoggedIn } from './api/requests';
import BrokenTools from './pages/broken-tools/brokenTools';
import Index from './pages/index';
import LiveGraphs from './pages/live-graphs/liveGraphs';
import Login from './pages/login/login';
import MachineLog from './pages/machine-log/machineLog';
import ManagementView from './pages/management-view/managementView';
import MeasurementInterval from './pages/measurement-interval/measurementInterval';
import Operators from './pages/operators/operators';
import Overlook from './pages/overlook/overlook';
import PotentialScraps from './pages/potential-scraps/potentialScraps';
import ProtocolList from './pages/protocol-list/protocolList';
import ProtocolView from './pages/protocol-view/protocolView';
import RecordList from './pages/record-list/recordList';
import RecordView from './pages/record-view/recordView';
import SpcDetails from './pages/spc-details/spcDetails';
import SpcList from './pages/spcList';

import 'react-toastify/dist/ReactToastify.css';
import '../css/style.scss';
import ReportingTool from './pages/reporting-tool/reportingTool';


export default function App() {

    const { data: isLoggedIn } = useQuery({
        queryKey: ['isLoggedIn'],
        queryFn: async () => await fetchIsLoggedIn(),
    });

    const { data: isAdmin } = useQuery({
        queryKey: ['isAdmin'],
        queryFn: async () => await fetchIsAdmin(),
        enabled: isLoggedIn,
        refetchOnWindowFocus: false,
    });

    return (
        <StrictMode>
            <Router>
                <Routes>
                    <Route path='/login' element={<Login />} />

                    <Route element={<ProtectedRoute isLoggedIn={isLoggedIn} />}>
                        <Route path='/' element={<Index />} />
                        <Route path='/measurement-interval' element={<MeasurementInterval />} />
                        <Route path='/machine-log' element={<MachineLog />} />
                        <Route path='/overlook' element={<Overlook />} />
                        <Route path='/potential-scraps' element={<PotentialScraps />} />
                        <Route path='/management-view' element={<ManagementView />} />
                        <Route path='/live-graphs' element={<LiveGraphs />} />
                        <Route path='/broken-tools' element={<BrokenTools />} />
                        <Route path='/record-list' element={<RecordList />} />
                        <Route path='/record-view' element={<RecordView />} />
                        <Route path='/spc-list' element={<SpcList />} />
                        <Route path='/spc-details' element={<SpcDetails />} />

                        <Route element={<AdminRoute isAdmin={isLoggedIn && isAdmin} />}>
                            <Route path='/protocol-list' element={<ProtocolList />} />
                            <Route path='/protocol-view' element={<ProtocolView />} />
                            <Route path='/operators' element={<Operators />} />
                            <Route path='/reporting-tool' element={<ReportingTool />} />
                        </Route>
                    </Route>

                    <Route path='*' element={<NotFound />} />
                </Routes>
            </Router>

            <ToastContainer
                position='bottom-center'
                autoClose={4000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                pauseOnFocusLoss
                pauseOnHover
                theme='light'
                transition={Flip}
            />
        </StrictMode>
    );
}


function ProtectedRoute({ isLoggedIn }: { isLoggedIn: boolean | null }) {
    const navigate = useNavigate();

    if (isLoggedIn == null) {
        return <p>Loading...</p>;
    } else if (isLoggedIn === false) {
        navigate('/login');
        return null;
    } else {
        return <Outlet />;
    }
}

function AdminRoute({ isAdmin }: { isAdmin: boolean | null }) {
    if (isAdmin == null) {
        return <p>Loading...</p>;
    } else if (isAdmin === false) {
        return <AccessDenied />;
    } else {
        return <Outlet />;
    }
}


function NotFound() {
    return <h1>Page Not Found</h1>;
}

function AccessDenied() {
    return <h1>Access Denied</h1>;
}
