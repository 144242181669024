import { ProtocolReview } from '../../../../shared/types/protocol';
import { ProtocolDraft } from '../../types/sharedTypeImpl';


/** Validates and creates an alert if not valid */
export function validateProtocolDraft(protocol: ProtocolDraft): boolean {
    if (!protocol.partName) {
        alert('Part number cannot be empty');
        return false;
    }
    if (!protocol.partRevision) {
        alert('Revision cannot be empty');
        return false;
    }

    for (const position of protocol.positions ?? []) {
        if (!position.positionNumber) {
            alert('Position number cannot be empty');
            return false;
        }

        const positionNumber = +position.positionNumber;
        if (positionNumber <= 0 || isNaN(positionNumber)) {
            alert('Position number is invalid');
            return false;
        }


        if (position.type === 'measurement') {
            if (!position.nominal) {
                alert('Nominal value cannot be empty');
                return false;
            }
            if (!position.upperTolerance) {
                alert('Upper tolerance cannot be empty');
                return false;
            }
            if (!position.lowerTolerance) {
                alert('Lower tolerance cannot be empty');
                return false;
            }

            const nominal = +position.nominal;
            if (nominal < 0 || isNaN(nominal)) {
                alert('Nominal value is invalid');
                return false;
            }

            const upperTolerance = +position.upperTolerance;
            if (upperTolerance < 0 || isNaN(upperTolerance)) {
                alert('Upper tolerance is invalid');
                return false;
            }

            const lowerTolerance = +position.lowerTolerance;
            if (lowerTolerance > 0 || isNaN(lowerTolerance)) {
                alert('Lower tolerance is invalid');
                return false;
            }

            if (!position.toolType) {
                alert('Tool type must be set');
                return false;
            }
        }
    }

    const uniquePosNums = new Set<string>(protocol.positions?.map(it => it.positionNumber));
    if (uniquePosNums.size !== protocol.positions.length) {
        alert('Position numbers must be unique');
        return false;
    }

    return true;
}

export function validateProtocolReview(review: ProtocolReview, protocol: ProtocolDraft): boolean {
    if (!review.operator) {
        alert('Operator name cannot be empty');
        return false;
    }
    if (review.operator === protocol.user) {
        alert('Reviewer cannot be the same as the protocol creator');
        return false;
    }

    if (!review.approved && !review.comment) {
        alert('Must set a comment when rejecting');
        return false;
    }

    return true;
}