import { useEffect, useRef } from 'react';
import { OeeRow } from '../../types/sharedTypeImpl';
import { OeeGraphOptions } from '../oee-graph-controls/oeeGraphOptios';
import { drawGraph } from './drawGraph';
import { transformData } from './transformData';


interface ReportingToolGraphProps {
    style?: React.CSSProperties;
    oeeData: OeeRow[];
    oeeGraphOptions: OeeGraphOptions;
}

export default function ReportingToolGraph({
    style,
    oeeData,
    oeeGraphOptions,
}: ReportingToolGraphProps) {

    const graphContainerRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        const transformedData = transformData(oeeData, oeeGraphOptions);
        drawGraph(graphContainerRef.current, transformedData);
    }, [oeeData, oeeGraphOptions]);


    return (
        <div style={{ ...style, height: '100%' }}
            ref={graphContainerRef}
        />
    )
}
