

export function getCountMap<T, K>(
    array: T[],
    getKey: (item: T) => K
): Map<K, number> {
    const countMap = new Map<K, number>();

    for (const item of array) {
        const key = getKey(item);
        countMap.set(key, (countMap.get(key) || 0) + 1);
    }

    return countMap;
}
