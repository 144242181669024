import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { fetchOee } from '../../api/requests';
import NavBar from '../../components/navBar';
import OeeGraphControls from '../../components/oee-graph-controls/oeeGraphControls';
import { OeeGraphOptions } from '../../components/oee-graph-controls/oeeGraphOptios';
import ReportingToolGraph from '../../components/reporting-tool-graph/reportingToolGraph';


export default function ReportingTool() {

    const [oeeGraphOptions, setOeeGraphOptions] = useState<OeeGraphOptions>({
        visibleShifts: [
            'morning',
            'evening',
            'night',
        ],
        excludeWeekends: false,
    });


    const startDate = useMemo(() => {
        return new Date(+new Date() - 42 * 24 * 60 * 60 * 1000)
    }, []);

    const { data: oeeData } = useQuery({
        queryKey: ['fetchOee', 'day', 'All equipments', startDate],
        queryFn: async () => await fetchOee({ windowType: 'shift', equipment: 'All equipments', start: startDate, }),
        refetchOnWindowFocus: false,
    });



    return (<>
        <NavBar />
        <div className='content'>
            <div style={{
                height: '90vh',
                display: 'flex', flexDirection: 'row'
            }}>
                <OeeGraphControls
                    style={{ minWidth: '300px', width: '25%', fontSize: '20px' }}
                    shitOptionsOnly={true}
                    oeeGraphOptions={oeeGraphOptions}
                    setOeeGraphOptions={setOeeGraphOptions}
                />

                {!oeeData
                    ? 'Loading...'
                    : <ReportingToolGraph
                        style={{ width: '50%' }}
                        oeeData={oeeData}
                        oeeGraphOptions={oeeGraphOptions}
                    />
                }
            </div>
        </div>
    </>);
}
