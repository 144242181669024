import { ChangeEvent, KeyboardEvent, useState } from 'react';
import attemptAddProgramName from './attemptAddProgramName';


interface ProgramNamesListProps {
    programNames: string[];
    setProgramNames: (programNames: string[]) => void;
    readOnly: boolean;
}

export default function ProgramNamesList({
    programNames,
    setProgramNames,
    readOnly,
}: ProgramNamesListProps) {

    const [programNameInput, setProgramNameInput] = useState<string>('');


    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setProgramNameInput(e.target.value);
    };

    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        attemptSubmitProgramName();
    }

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if ((e.key === 'Enter' || e.key === ',')) {
            e.preventDefault();
            attemptSubmitProgramName();
        }
    };

    const handleAddButton = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        attemptSubmitProgramName();
    };

    const attemptSubmitProgramName = () => {
        const { isValid, newProgramNames } = attemptAddProgramName(programNames, programNameInput);
        if (isValid && newProgramNames) {
            setProgramNames(newProgramNames);
            setProgramNameInput('');
        }
    }

    const removeProgramName = (index: number) => {
        setProgramNames(programNames.filter((_, i) => i !== index));
    };


    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                {programNames.map((product, index) => (
                    <div
                        key={product}
                        style={{
                            border: '1px solid black',
                            padding: readOnly ? '7px 10px' : '0px 5px',
                            margin: '1px',
                            borderRadius: '5px',
                            display: 'inline-flex',
                            alignItems: 'center'
                        }}
                    >
                        {product}
                        {!readOnly &&
                            <span
                                onClick={() => removeProgramName(index)}
                                style={{
                                    margin: '0px 5px',
                                    color: '#f00',
                                    fontSize: '30px',
                                    fontWeight: 'bold',
                                }}
                            >
                                ×
                            </span>
                        }
                    </div>
                ))}
            </div>
            {!readOnly &&
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <input
                        style={{ flexGrow: 1 }}
                        type='text'
                        value={programNameInput}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        onBlur={handleInputBlur}
                        placeholder='Type a program name and press Enter...' />
                    <button onClick={handleAddButton}>
                        Add
                    </button>
                </div>
            }
        </div>
    );
}
