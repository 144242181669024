import { getPositionIdxValues } from '../../../../shared/services/getPositionValues';
import { ControlValues, getControlValues } from '../../../../shared/services/spc/getControlValues';
import { Protocol, RecordWithProtocol } from '../../types/sharedTypeImpl';


export interface SigmaHistogramData {
    histogram: SigmaHistogramBucket[],
    limitValues: number[],
}
export interface SigmaHistogramBucket {
    label: string,
    count: number,
}


export default function (
    protocol: Protocol,
    records: RecordWithProtocol[],
    activePositionIdx: number,
    numLatest: number | null,
): SigmaHistogramData | null {

    const values = getPositionIdxValues(protocol, records, activePositionIdx);
    const controlLimits = getControlValues(values, protocol.positions[activePositionIdx]);
    if (controlLimits == null) return null;

    const latestValues = numLatest == null ? values : values.slice(-numLatest);
    const histogram = getHistogram(latestValues, controlLimits);
    const limitValues = getLimitValues(controlLimits);
    return { histogram, limitValues };
}


function getHistogram(values: string[], controlLimits: ControlValues): SigmaHistogramBucket[] {
    const { mean, sigma1, sigma2, sigma3 } = controlLimits;

    const counts = Array(8).fill(0);
    for (const valueString of values) {
        const value = parseFloat(valueString);

        if (value < sigma3[0]) counts[0]++;
        else if (value < sigma2[0]) counts[1]++;
        else if (value < sigma1[0]) counts[2]++;
        else if (value < mean) counts[3]++;
        else if (Math.abs(value - mean) < 0.0001) { counts[3] += 0.5; counts[4] += 0.5; }
        else if (value <= sigma1[1]) counts[4]++;
        else if (value <= sigma2[1]) counts[5]++;
        else if (value <= sigma3[1]) counts[6]++;
        else counts[7]++;
    }

    return [
        { label: '', count: counts[0] },
        { label: '-3σ', count: counts[1] },
        { label: '-2σ', count: counts[2] },
        { label: '-1σ', count: counts[3] },
        { label: 'σ', count: counts[4] },
        { label: '2σ', count: counts[5] },
        { label: '3σ', count: counts[6] },
        { label: '', count: counts[7] },
    ];
}

function getLimitValues(controlValues: ControlValues): number[] {
    const { mean, sigma1, sigma2, sigma3 } = controlValues;
    return [
        sigma3[0],
        sigma2[0],
        sigma1[0],
        mean,
        sigma1[1],
        sigma2[1],
        sigma3[1],
    ];
}
