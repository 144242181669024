import { getPositionIdxValues } from '../../../../shared/services/getPositionValues';
import { getControlValues } from '../../../../shared/services/spc/getControlValues';
import { getToleranceRangeStrings } from '../../../../shared/utils/getToleranceRange';
import { getCpk } from '../../services/getCpk';
import { Protocol, RecordWithProtocol } from '../../types/sharedTypeImpl';


interface PositionDetailsProps {
    style?: React.CSSProperties,
    protocol: Protocol,
    records: RecordWithProtocol[],
    activePositionIdx: number,
}

export default function PositionDetails({
    style,
    protocol,
    records,
    activePositionIdx,
}: PositionDetailsProps) {

    const position = protocol.positions[activePositionIdx];
    const toleranceRange = getToleranceRangeStrings(position);

    const positionValues = getPositionIdxValues(protocol, records, activePositionIdx);
    const controlValues = getControlValues(positionValues, position);
    const cpk = getCpk(controlValues);


    return (
        <table style={style}>
            <tbody>
                <tr>
                    <td>Nominal</td>
                    <td>{position.nominal}</td>
                </tr>
                <tr>
                    <td>Mean</td>
                    <td>{controlValues?.mean.toFixed(3)}</td>
                </tr>
                <tr>
                    <td>Stddev</td>
                    <td>{controlValues?.stddev.toFixed(3)}</td>
                </tr>
                <tr>
                    <td>Sigma 1</td>
                    <td>{controlValues && `${controlValues.sigma1[0].toFixed(3)} / ${controlValues.sigma1[1].toFixed(3)}`}</td>
                </tr>
                <tr>
                    <td>Sigma 2</td>
                    <td>{controlValues && `${controlValues.sigma2[0].toFixed(3)} / ${controlValues.sigma2[1].toFixed(3)}`}</td>
                </tr>
                <tr>
                    <td>Sigma 3</td>
                    <td>{controlValues && `${controlValues.sigma3[0].toFixed(3)} / ${controlValues.sigma3[1].toFixed(3)}`}</td>
                </tr>
                <tr>
                    <td>Upper tolernace</td>
                    <td>{position.upperTolerance} &rarr; {toleranceRange.max}</td>
                </tr>
                <tr>
                    <td>Lower tolerance</td>
                    <td>{position.lowerTolerance} &rarr; {toleranceRange.min}</td>
                </tr>
                <tr>
                    <td>Cpk</td>
                    <td style={{ backgroundColor: (cpk < 1 ? '#ffdebb' : undefined) }}>
                        {cpk.toFixed(3)}
                    </td>
                </tr>
                <tr>
                    <td>Measurements</td>
                    <td>{positionValues.length}</td>
                </tr>
            </tbody>
        </table>
    )
}
