import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { sortBy } from '../../../../shared/utils/arrayUtils';
import { fetchCurrentSession, fetchEquipments, fetchPartNames, fetchProducts } from '../../api/requests';
import useFetchOnce from '../../hooks/useFetchOnce';
import MaintenanceLogDialog from './maintenanceLogDialog';
import { SelectedPart } from './selectedPart';


interface SessionControlBarProps {
    selectedEquipment: string | null;
    setSelectedEquipment: (equipment: string | null) => void;
    selectedPart: SelectedPart | null;
    setSelectedPart: (partName: SelectedPart | null) => void;
}

export default function SessionControlBar({
    selectedEquipment,
    setSelectedEquipment,
    selectedPart,
    setSelectedPart,
}: SessionControlBarProps) {

    const [isMaintenanceLogDialogOpen, setIsMaintenanceLogDialogOpen] = useState(false);


    const { data: equipments } = useFetchOnce({
        queryKey: ['equipments'],
        queryFn: () => fetchEquipments(),
        select: (equipments) => sortBy([...equipments], it => it.displayName),
        refetchOnWindowFocus: false,
        refetchInterval: 30 * 60_000,
    });

    const { data: partNames } = useQuery({
        queryKey: ['part-names'],
        queryFn: () => fetchPartNames(),
        select: (partNames) => [...partNames.sort()],
        refetchInterval: 10 * 60_000,
    });

    const { data: products } = useQuery({
        queryKey: ['products'],
        queryFn: () => fetchProducts(),
        select: (products) => [...products.sort()],
        refetchInterval: 10 * 60_000,
    });

    const { data: currentEquipmentSession } = useQuery({
        queryKey: ['currentSession', selectedEquipment],
        queryFn: () => fetchCurrentSession(selectedEquipment!),
        enabled: selectedEquipment !== null,
        refetchInterval: 10 * 60_000,
    });


    //Set selected equipment or product from url hash
    useEffect(() => {
        if (!equipments) return;

        const hashParams = new URLSearchParams(window.location.hash.substring(1));
        const equipment = hashParams.get('equipment');
        const partName = hashParams.get('partName');
        const product = hashParams.get('product');
        if (equipment) {
            setSelectedEquipment(equipment);
        } else if (partName || product) {
            setSelectedEquipment(null);
            setSelectedPart({ partName, product });
        }
    }, [equipments, setSelectedEquipment, setSelectedPart]);

    // On session change
    useEffect(() => {
        if (selectedEquipment == null) return;// should only be null, when partName or product is selected or when first loading the page
        const partName = currentEquipmentSession?.partName;
        const product = currentEquipmentSession?.product;
        setSelectedPart(partName || product ? { partName, product } : null);
    }, [selectedEquipment, currentEquipmentSession?.partName, currentEquipmentSession?.product, setSelectedPart]);


    const handleEquipmentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedEquipment(e.target.value);
        setUrlHash('equipment=' + e.target.value);
    }

    const handlePartNameSubmit = (partName: string) => {
        setSelectedPart({ partName, product: undefined });
        setSelectedEquipment(null);
        setUrlHash('partName=' + partName);
    }

    const handleProductSubmit = (product: string) => {
        setSelectedPart({ partName: undefined, product });
        setSelectedEquipment(null);
        setUrlHash('product=' + product);
    }

    const handleMaintenanceLogClick = () => {
        setIsMaintenanceLogDialogOpen(true);
    }

    const handleGuideClick = () => {
        const baseUrl = 'https://berryglade.sharepoint.com/sites/BGNET/Sorvikohtaiset%20ohjeet/';
        const url = baseUrl + encodeURIComponent(selectedEquipment!);
        window.open(url, '_blank');
    }

    const setUrlHash = (hash: string) => {
        history.replaceState(null, null, document.location.pathname + '#' + hash);
    }


    return (
        <div style={{ display: 'flex', gap: '8px' }}>
            <select
                style={{ width: '180px' }}
                value={selectedEquipment || ''}
                onChange={handleEquipmentChange}
            >
                <option value={''} disabled hidden />
                {equipments?.map(equipment => (
                    <option key={equipment.id} value={equipment.id}>{equipment.displayName}</option>
                ))}
            </select>

            <InputWithSuggestions
                dataId='partNameForm'
                label='Part number'
                defaultValue={selectedPart?.partName ?? ''}
                suggestions={partNames ?? []}
                onSubmit={handlePartNameSubmit}
            />

            <InputWithSuggestions
                dataId='productForm'
                label='Program name'
                defaultValue={selectedPart?.product ?? ''}
                suggestions={products ?? []}
                onSubmit={handleProductSubmit}
            />

            <div className='flex__spacer' />

            <button onClick={handleMaintenanceLogClick} disabled={selectedEquipment == null}>Maintenance log</button>
            <button onClick={handleGuideClick} disabled={selectedEquipment == null}>Guide</button>

            <MaintenanceLogDialog
                isOpen={isMaintenanceLogDialogOpen}
                equipment={selectedEquipment}
                onClose={() => setIsMaintenanceLogDialogOpen(false)}
            />
        </div>
    )
}


function InputWithSuggestions({
    dataId,
    label,
    defaultValue,
    suggestions,
    onSubmit,
}: {
    dataId: string,
    label: string,
    defaultValue: string,
    suggestions: string[],
    onSubmit: (value: string) => void,
}) {
    const [value, setValue] = useState(defaultValue);
    const [isFocused, setIsFocused] = useState(false);


    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(value);
    }

    return (
        <form
            style={{ marginLeft: '12px' }}
            onSubmit={handleSubmit}
        >
            <label>{label}:</label>
            <input
                style={{ marginLeft: '6px' }}
                type='text'
                value={value}
                list={dataId}
                onChange={handleInput}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            />
            {isFocused && (
                <datalist id={dataId}>
                    {suggestions.map(suggestion => (
                        <option key={suggestion} value={suggestion} />
                    ))}
                </datalist>
            )}
            <input type='submit' value='Submit' />
        </form>
    )
}
