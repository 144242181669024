import { Dispatch, SetStateAction, useState } from 'react';
import { Point } from '../../../../shared/types/mathTypes';
import PositionSelection from '../../types/positionSelection';
import { ProtocolDraft } from '../../types/sharedTypeImpl';
import FormPositionMenu from './formPositionMenu';
import { MeasurementPosition } from './measurementPosition';
import { useUpdateProtocol } from './useUpdateProtocol';
import { VisualInspectionPosition } from './visualInspectionPosition';


interface ProtocolFormPositionsProps {
    isReadOnly: boolean;
    protocol: ProtocolDraft | null,
    setProtocol: Dispatch<SetStateAction<ProtocolDraft | null>>,
    positionSelection: PositionSelection | null,
    setPositionSelection: (positionSelection: PositionSelection | null) => void,
    showAllPositions: boolean,
    setShowAllPositions: (showAllPositions: boolean) => void,
    setIsDirty: (isDirty: boolean) => void
}

export default function ProtocolFormPositions({
    isReadOnly,
    protocol,
    setProtocol,
    positionSelection,
    setPositionSelection,
    showAllPositions,
    setShowAllPositions,
    setIsDirty,
}: ProtocolFormPositionsProps) {
    const [positionMenuLoc, setPositionMenuLoc] = useState<Point | null>(null);

    const {
        setMarkerField,
        reorderPositions,
        removePosition,
        removeMarker,
    } = useUpdateProtocol(protocol, setProtocol, setPositionSelection, setIsDirty);


    function handleMenuOpen(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        if (isReadOnly) return;
        console.log({ x: e.screenX, y: e.screenY });
        setPositionMenuLoc({ x: e.pageX, y: e.pageY });
    }


    return (
        <>
            <table className='measurement-form__positions'>
                <thead>
                    <tr>
                        <th style={{ width: '65px' }} />
                        <th>Nominal</th>
                        <th style={{ width: '70px' }} >Symbol</th>
                        <th>Upper tol.</th>
                        <th>Lower tol.</th>
                        <th style={{ width: '70px' }} >Use GT</th>
                        <th style={{ width: '77px' }} >Chamfer</th>
                        <th style={{ width: '88px' }} >Tool</th>
                        <th style={{ width: '40px' }} />
                    </tr>
                </thead>
                <tbody>
                    {protocol.positions?.map((it, index) =>
                        it.type === 'measurement'
                            ? <MeasurementPosition
                                key={it.key}
                                index={index}
                                protocol={protocol}
                                setProtocol={setProtocol}
                                positionSelection={positionSelection}
                                setPositionSelection={setPositionSelection}
                                setIsDirty={setIsDirty}
                                isReadOnly={isReadOnly}
                                onMenuOpenClick={handleMenuOpen}
                            />
                            : <VisualInspectionPosition
                                key={it.key}
                                index={index}
                                protocol={protocol}
                                setProtocol={setProtocol}
                                positionSelection={positionSelection}
                                setPositionSelection={setPositionSelection}
                                setIsDirty={setIsDirty}
                                isReadOnly={isReadOnly}
                                onMenuOpenClick={handleMenuOpen}
                            />
                    )}
                </tbody>
            </table>

            <div className='flex--horz' style={{ gap: '20px', justifyContent: 'left', marginTop: '8px' }}>
                {!isReadOnly &&
                    <button
                        style={{ display: 'block' }}
                        type='button'
                        onClick={reorderPositions} >
                        Reorder positions
                    </button>
                }
                <label className='with-checkbox'>
                    Show all positions
                    <input
                        type='checkbox'
                        checked={showAllPositions}
                        onChange={(e) => setShowAllPositions(e.target.checked)}
                    />
                </label>
            </div>

            {positionMenuLoc && <FormPositionMenu
                protocol={protocol}
                positionSelection={positionSelection}
                screenCoords={positionMenuLoc}
                setMarkerField={setMarkerField}
                removePosition={removePosition}
                removeMarker={removeMarker}
                onClose={() => setPositionMenuLoc(null)}
            />}
        </>
    )
}
