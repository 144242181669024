import { Position } from '../../../../shared/types/protocol';
import PositionSelection from '../../types/positionSelection';


interface FormMarkerListProps {
    position: Position,
    positionIdx: number,
    positionSelection: PositionSelection | null,
    setPositionSelection: (positionSelection: PositionSelection) => void,
    onMenuOpenClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
}

export default function FormMarkerList({
    position,
    positionIdx,
    positionSelection,
    setPositionSelection,
    onMenuOpenClick,
}: FormMarkerListProps) {

    return (
        <>
            {position.markers.map((marker, markerIdx) =>
                <PositionMarker
                    key={markerIdx}
                    positionIdx={positionIdx}
                    markerIdx={markerIdx}
                    positionSelection={positionSelection}
                    setPositionSelection={setPositionSelection}
                    onMenuOpenClick={onMenuOpenClick}
                />
            )}
        </>
    );
}


interface PositionMarkerProps {
    // marker: PositionMarker,
    positionIdx: number,
    markerIdx: number,
    positionSelection: PositionSelection | null,
    setPositionSelection: (positionSelection: PositionSelection) => void,
    onMenuOpenClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
}

function PositionMarker({
    // marker,
    positionIdx,
    markerIdx,
    positionSelection,
    setPositionSelection,
    onMenuOpenClick,
}: PositionMarkerProps) {

    const isActive = positionSelection != null
        && positionSelection.positionIdx === positionIdx
        && positionSelection.markerIdx === markerIdx;

    return (
        <tr
            className={`${isActive && 'highlight--selected'}`}
            onClick={() => setPositionSelection({ positionIdx, markerIdx })}
        >
            <td colSpan={8}>
                <div style={{ textAlign: 'left', marginLeft: '32px' }}>
                    Marker {markerIdx + 1}
                </div>
            </td>
            <td>
                <button
                    className='td__menu-button'
                    onClick={onMenuOpenClick}
                    onFocus={() => setPositionSelection({ positionIdx, markerIdx })}>
                    &#8942;
                </button>
            </td>
        </tr>
    )
}
