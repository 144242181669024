import LabelWithBorder from '../labelWithBorder';
import lineStyles from './lineStyles';
import MeasurementGraphSettings from './measurementGraphSettings';


interface MeasurementGraphControlProps {
    settings: MeasurementGraphSettings | null,
    setSettings: (settings: MeasurementGraphSettings) => void,
}

export default function MeasurementGraphControl({
    settings,
    setSettings,
}: MeasurementGraphControlProps) {

    const handleCheckboxChange = (key: keyof MeasurementGraphSettings['lineVisibilities']) => {
        setSettings({
            ...settings,
            lineVisibilities: {
                ...settings.lineVisibilities,
                [key]: !settings.lineVisibilities[key],
            }
        });
    }


    const labelStyle: React.CSSProperties = { padding: '2px 13px 4px 10px', borderWidth: '2px' };
    const checkBoxStyle: React.CSSProperties = { marginRight: '6px' };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: '24px',
            padding: '0px 20px',
        }}>
            <LabelWithBorder
                style={labelStyle}
                borderBottomColor={lineStyles.nominal.color}
                borderBottomDashArray={lineStyles.nominal.dashArray}
            >
                <input
                    style={checkBoxStyle}
                    type='checkbox'
                    checked={settings.lineVisibilities.nominal ?? false}
                    onChange={() => handleCheckboxChange('nominal')}
                />
                Nominal
            </LabelWithBorder>
            <LabelWithBorder
                style={labelStyle}
                borderBottomColor={lineStyles.tolerance.color}
                borderBottomDashArray={lineStyles.tolerance.dashArray}
            >
                <input
                    style={checkBoxStyle}
                    type='checkbox'
                    checked={settings.lineVisibilities.tolerance ?? false}
                    onChange={() => handleCheckboxChange('tolerance')}
                />
                Tolerance
            </LabelWithBorder>
            <LabelWithBorder
                style={labelStyle}
                borderBottomColor={lineStyles.mean.color}
                borderBottomDashArray={lineStyles.mean.dashArray}
            >
                <input
                    style={checkBoxStyle}
                    type='checkbox'
                    checked={settings.lineVisibilities.mean ?? false}
                    onChange={() => handleCheckboxChange('mean')}
                />
                Mean
            </LabelWithBorder>
            <LabelWithBorder
                style={labelStyle}
                borderBottomColor={lineStyles.sigma1.color}
                borderBottomDashArray={lineStyles.sigma1.dashArray}
            >
                <input
                    style={checkBoxStyle}
                    type='checkbox'
                    checked={settings.lineVisibilities.sigma1 ?? false}
                    onChange={() => handleCheckboxChange('sigma1')}
                />
                1σ
            </LabelWithBorder>
            <LabelWithBorder
                style={{ ...labelStyle }}
                borderBottomColor={lineStyles.sigma2.color}
                borderBottomDashArray={lineStyles.sigma2.dashArray}
            >
                <input
                    style={checkBoxStyle}
                    type='checkbox'
                    checked={settings.lineVisibilities.sigma2 ?? false}
                    onChange={() => handleCheckboxChange('sigma2')}
                />
                2σ
            </LabelWithBorder>
            <LabelWithBorder
                style={labelStyle}
                borderBottomColor={lineStyles.sigma3.color}
                borderBottomDashArray={lineStyles.sigma3.dashArray}
            >
                <input
                    style={checkBoxStyle}
                    type='checkbox'
                    checked={settings.lineVisibilities.sigma3 ?? false}
                    onChange={() => handleCheckboxChange('sigma3')}
                />
                3σ
            </LabelWithBorder>
        </div >
    )
}
