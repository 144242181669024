import * as d3 from 'd3';
import { SPC_DESCRIPTIONS } from '../../../../shared/services/spc/spcCheckPosition';
import { clamp } from '../../../../shared/utils/clamp';
import { formatDatetime } from '../../utils/fieldFormats';
import { MetaData } from './getMetaData';
import lineStyles from './lineStyles';
import MeasurementGraphSettings from './measurementGraphSettings';
import { DataPoint } from './transformDataPoints';


type SVGElement = d3.Selection<SVGGElement, unknown, null, undefined>;
type GElement = d3.Selection<SVGGElement, unknown, null, undefined>;

type GraphDimens = {
    width: number,
    height: number,
    margins: { top: number, right: number, bottom: number, left: number },
}
type Graph = d3.Selection<SVGGElement, GraphDimens, null, undefined>;


export default function drawMeasurementGraph(
    container: HTMLDivElement,
    dataPoints: DataPoint[],
    metaData: MetaData,
    drawBorder: boolean,
    settings: MeasurementGraphSettings,
) {
    const root = createRootElement(container, metaData, drawBorder);
    if (dataPoints.length === 0) {
        drawNoDataMessage(root);
        return;
    }

    const graph = createGraphElement(root);
    const { x, y } = createAxes(root, graph, metaData, dataPoints);
    drawControlValues(graph, y, metaData, settings);
    drawMeasurements(container, graph, x, y, metaData, dataPoints);
}


function createRootElement(container: HTMLDivElement, metaData: MetaData, drawBorder: boolean) {
    const rect = container.getBoundingClientRect();
    const containerWidth = rect.width;
    const containerHeight = rect.height;

    let root = d3.select(container).select('svg') as SVGElement;
    if (root.empty()) {
        root = d3.select(container)
            .append('svg')
            .attr('width', containerWidth)
            .attr('height', containerHeight);
    } else {
        root.html('');
    }

    if (!drawBorder) {
        root.style('border', 'none');
    } else if (metaData.isLastValueFlagged) {
        root.style('border', '2px solid red');
    } else {
        root.style('border', '2px solid #aaa');
    }
    return root;
}


function drawNoDataMessage(root: SVGElement) {
    root.append('text')
        .attr('x', +root.attr('width') / 2)
        .attr('y', +root.attr('height') / 2)
        .attr('text-anchor', 'middle')
        .text('No data available');
}

function createGraphElement(root: SVGElement): Graph {
    const graphMargins = { top: 15, right: 20, bottom: 30, left: 50 };
    const graphWidth = +root.attr('width') - graphMargins.left - graphMargins.right;
    const graphHeight = +root.attr('height') - graphMargins.top - graphMargins.bottom;

    let graph = root.select('g') as Graph;
    if (graph.empty()) {
        graph = root.append('g').attr('transform', `translate(${graphMargins.left}, ${graphMargins.top})`) as Graph;
    }
    graph.datum({ width: graphWidth, height: graphHeight, margins: graphMargins });

    return graph;
}

function createAxes(
    root: SVGElement,
    graph: Graph,
    metaData: MetaData,
    dataPoints: DataPoint[],
) {
    const { width: graphWidth, height: graphHeight, margins: graphMargins } = graph.datum();
    const textSize = getTextSize(+root.attr('width'));

    const labelMargin = 5;
    const largestPartCount = Math.max(...dataPoints.map(it => it.partCount));
    const labelWidth = getTextWidth(graph, '' + largestPartCount, textSize);
    const maxLabels = Math.floor(graphWidth / (labelWidth + labelMargin));
    const labelFrequency = Math.max(Math.ceil(dataPoints.length / maxLabels), 1);
    const isLabelVisible = (i: number) => { return (dataPoints.length - 1 - i) % labelFrequency === 0; }// The last label is always visible

    const x = d3
        .scalePoint()
        .range([0, graphWidth])
        .domain(dataPoints.map((_, index) => '' + index));
    root.append('g')
        .attr('transform', `translate(${graphMargins.left}, ${graphHeight + graphMargins.top})`)
        .call(d3.axisBottom(x).tickFormat((_, i) => isLabelVisible(i) ? '' + dataPoints[i].partCount : ''))
        .selectAll('text')
        .style('font-size', textSize + 'px');

    const y = d3
        .scaleLinear()
        .range([graphHeight, 0])
        .domain(metaData.graphLimits);
    root.append('g')
        .attr('transform', `translate(${graphMargins.left}, ${graphMargins.top})`)
        .call(d3.axisLeft(y))
        .selectAll('text')
        .style('font-size', textSize + 'px');
    return { x, y };
}

function drawControlValues(
    graph: Graph,
    y: d3.ScaleLinear<number, number, never>,
    metaData: MetaData,
    settings: MeasurementGraphSettings,
) {
    const graphWidth = graph.datum().width;
    const lineThickness = 0.5 + graph.datum().height * 0.004;// should be around 1.5 pixels
    const lines: { value: number, color: string, dashArray?: string, visibility: boolean }[] = [
        { value: metaData.nominal, ...lineStyles.nominal, visibility: settings.lineVisibilities.nominal },
        { value: metaData.tolerances[0], ...lineStyles.tolerance, visibility: settings.lineVisibilities.tolerance },
        { value: metaData.tolerances[1], ...lineStyles.tolerance, visibility: settings.lineVisibilities.tolerance },
        { value: metaData.mean, ...lineStyles.mean, visibility: settings.lineVisibilities.mean },
        { value: metaData.sigma1[0], ...lineStyles.sigma1, visibility: settings.lineVisibilities.sigma1 },
        { value: metaData.sigma1[1], ...lineStyles.sigma1, visibility: settings.lineVisibilities.sigma1 },
        { value: metaData.sigma2[0], ...lineStyles.sigma2, visibility: settings.lineVisibilities.sigma2 },
        { value: metaData.sigma2[1], ...lineStyles.sigma2, visibility: settings.lineVisibilities.sigma2 },
        { value: metaData.sigma3[0], ...lineStyles.sigma3, visibility: settings.lineVisibilities.sigma3 },
        { value: metaData.sigma3[1], ...lineStyles.sigma3, visibility: settings.lineVisibilities.sigma3 },
    ];
    for (const line of lines) {
        if (!line.visibility) continue;
        graph.append('line')
            .attr('x1', 0)
            .attr('y1', y(line.value) + lineThickness / 2)
            .attr('x2', graphWidth)
            .attr('y2', y(line.value) + lineThickness / 2)
            .attr('stroke-width', lineThickness)
            .attr('stroke', line.color)
            .attr('stroke-dasharray', line.dashArray ?? '0');
    }
}


function drawMeasurements(
    container: HTMLDivElement,
    graph: GElement,
    x: d3.ScalePoint<string>,
    y: d3.ScaleLinear<number, number, never>,
    metaData: MetaData,
    dataPoints: DataPoint[],
) {
    //Draw connecting lines between points
    for (let idx = 0; idx < dataPoints.length - 1; idx++) {
        if (!dataPoints[idx + 1].shouldDraw) continue;
        const lineGenerator = d3.line<DataPoint>()
            .x((_, i: number) => x(idx + i + ''))
            .y((d: DataPoint, i: number) => y(clamp(d.value, ...metaData.valueLimits)));

        const useRedLine = dataPoints[idx].hasIssues && dataPoints[idx + 1].hasIssues;
        graph.append('path')
            .datum([dataPoints[idx], dataPoints[idx + 1]])
            .attr('fill', 'none')
            .attr('stroke', useRedLine ? '#ff0000' : '#10A37F')
            .attr('stroke-width', 3)
            .attr('d', lineGenerator);
    }

    //Draw datapoints
    graph.selectAll('.point')
        .data(dataPoints.filter(it => it.shouldDraw))
        .enter().append('circle')
        .attr('class', 'point')
        .attr('cx', (d: DataPoint, i: number) => x(i + '') + x.bandwidth() / 2) // Centers the circle in the band
        .attr('cy', (d: DataPoint, i: number) => y(clamp(d.value, ...metaData.valueLimits)))
        .attr('r', 5)
        .attr('fill', (d: DataPoint, i: number) => d.hasIssues ? '#ff0000' : '#10A37F');


    //Extra detail on hover:
    const getToolTipText = (d: DataPoint) => {
        const value = `Value: ${d.valueAsString}`;
        const date = `Date: ${formatDatetime(d.measurementStart)}`;
        const partCount = `Part count: ${d.partCount}`;
        const explanations = d.checkResults.map((it, i) => it ? SPC_DESCRIPTIONS[i] : null).filter(it => it != null);
        const issuesLabel = d.hasIssues ? '\nIssues:' : '';
        const message = [value, date, partCount, issuesLabel, ...explanations].filter(Boolean).join('\n');
        return message;
    }

    let tooltip = d3.select('#tooltip');
    if (tooltip.empty()) {
        tooltip = d3.select(container)
            .append('div')
            .attr('id', 'tooltip')
            .style('position', 'absolute')
            .style('visibility', 'hidden')
            .style('background-color', 'white')
            .style('padding', '5px')
            .style('border', '1px solid #ccc')
            .style('font-size', '12px')
            .style('pointer-events', 'none')
            .style('top', '100px') //Make sure the initial position is within window bounds
            .style('left', '100px')
            .style('text-align', 'left')
            .style('white-space', 'pre-wrap'); // Ensure text wraps and supports line breaks
    }

    graph.selectAll('.point')
        .on('mouseover', (event, d: DataPoint) => {
            tooltip.html(getToolTipText(d))
                .style('visibility', 'visible');
        })
        .on('mousemove', (event) => {
            const padding = 10;
            const tooltipWidth = parseInt(tooltip.style('width'), 10);
            const tooltipHeight = parseInt(tooltip.style('height'), 10);

            let xPosition = event.pageX + padding;
            let yPosition = event.pageY - padding;
            if (xPosition + tooltipWidth > window.innerWidth) {// Adjust if tooltip overflows the right edge
                xPosition = event.pageX - tooltipWidth - padding;
            }
            if (yPosition + tooltipHeight > window.innerHeight) { // Adjust if tooltip overflows the bottom edge
                yPosition = event.pageY - tooltipHeight - padding;
            }

            tooltip.style('top', yPosition + 'px')
                .style('left', xPosition + 'px');
        })
        .on('mouseout', () => {
            tooltip.style('visibility', 'hidden');
        });
}


function getTextSize(containerWidth: number): number {
    if (containerWidth < 750) {
        return 10;
    } else {
        return 12;
    }
}

function getTextWidth(element: GElement, text: string, fontSize: number): number {
    // Create a temporary text element
    const tempText = element.append('text')
        .style('font-size', fontSize + 'px')
        .style('visibility', 'hidden') // Hide it to avoid disrupting layout
        .text(text);

    const width = tempText.node()?.getBBox().width ?? 0;

    tempText.remove();
    return width;
}
