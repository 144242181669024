import { DateTime } from 'luxon';


export function getWeekday(date: Date): number {
    const luxonDate = DateTime.fromJSDate(date).setZone('Europe/Helsinki');
    return luxonDate.weekday;
}

export function getWeekNumber(date: Date): number {
    const luxonDate = DateTime.fromJSDate(date).setZone('Europe/Helsinki');
    return luxonDate.weekNumber;
}
