import classNames from 'classnames';
import { useMemo } from 'react';
import { SPC_DESCRIPTIONS, SpcChecksArray } from '../../../../shared/services/spc/spcCheckPosition';
import { spcChecksAggregated } from '../../../../shared/services/spc/spcChecksAggregated';
import { Protocol, RecordWithProtocol } from '../../types/sharedTypeImpl';


const nullResultsBackground = '#00000028';

interface SpcChecksTableProps {
    style?: React.CSSProperties;
    protocol: Protocol;
    records: RecordWithProtocol[];
    activePositionIdx: number | null;
    setActivePositionIdx: (activePositionIdx: number | null) => void;
    enabledSpcChecks: Map<string, boolean[]>;
    onEnabledSpcCheckChanged: (positionNumber: string, colIdx: number, isEnabled: boolean) => void;
    numLatest?: number | null;
}

export default function SpcChecksTable({
    style,
    protocol,
    records,
    activePositionIdx,
    setActivePositionIdx,
    enabledSpcChecks,
    onEnabledSpcCheckChanged,
    numLatest,
}: SpcChecksTableProps) {

    const activePositionNumber = activePositionIdx != null && protocol.positions[activePositionIdx].positionNumber;

    const spcChecks = useMemo(() => {
        return spcChecksAggregated(protocol, records, numLatest);
    }, [protocol, records, numLatest,]);


    const handlePositionSelect = (positionNumber: string) => {
        // The table shows only a subset of all positions (visual inspections are excluded)
        const index = protocol.positions.findIndex(it => it.positionNumber === positionNumber);
        setActivePositionIdx(index);
    }

    return (
        <table className='spc-checks-table' style={style}>
            <thead>
                <tr>
                    <th />
                    <th title={SPC_DESCRIPTIONS[0]}>1</th>
                    <th title={SPC_DESCRIPTIONS[1]}>2</th>
                    <th title={SPC_DESCRIPTIONS[2]}>3</th>
                    <th title={SPC_DESCRIPTIONS[3]}>4</th>
                    <th title={SPC_DESCRIPTIONS[4]}>5</th>
                    <th title={SPC_DESCRIPTIONS[5]}>6</th>
                    <th title={SPC_DESCRIPTIONS[6]}>7</th>
                </tr>
            </thead>
            <tbody>
                {spcChecks.map(({ position, checks }) =>
                    <SpcCheckRow
                        key={position.positionNumber}
                        positionNumber={position.positionNumber}
                        isSelectedPosition={activePositionNumber === position.positionNumber}
                        positionChecks={checks}
                        enabledChecks={enabledSpcChecks.get(position.positionNumber)!}
                        onPositionSelect={handlePositionSelect}
                        onEnabledCheckChanged={onEnabledSpcCheckChanged}
                    />
                )}
            </tbody>
        </table>
    );
}


function SpcCheckRow({
    positionNumber,
    isSelectedPosition,
    positionChecks,
    enabledChecks,
    onEnabledCheckChanged,
    onPositionSelect,
}: {
    positionNumber: string,
    isSelectedPosition: boolean,
    positionChecks: SpcChecksArray,
    enabledChecks: boolean[],
    onPositionSelect: (positionNumber: string) => void,
    onEnabledCheckChanged: (positionNumber: string, colIdx: number, isEnabled: boolean) => void,
}) {

    const allChecksNull = positionChecks.every(check => check === null);

    return (
        <tr
            className={classNames({ 'highlight--selected': isSelectedPosition })}
            onClick={() => onPositionSelect(positionNumber)}
        >
            <td style={{ backgroundColor: allChecksNull ? nullResultsBackground : undefined }}>
                {positionNumber}
            </td>
            {positionChecks.map((spcCheckResult, colIdx) =>
                <SpcCheckCell
                    key={colIdx}
                    positionNumber={positionNumber}
                    colIdx={colIdx}
                    spcCheckResult={spcCheckResult}
                    title={SPC_DESCRIPTIONS[colIdx]}
                    isEnabled={enabledChecks[colIdx]}
                    onEnabledCheckChanged={onEnabledCheckChanged}
                />
            )}
        </tr>
    );
}


function SpcCheckCell({
    positionNumber,
    colIdx,
    spcCheckResult,
    title,
    isEnabled,
    onEnabledCheckChanged,
}: {
    positionNumber: string,
    colIdx: number,
    spcCheckResult: boolean | null,
    title: string,
    isEnabled: boolean,
    onEnabledCheckChanged: (positionNumber: string, colIdx: number, isEnabled: boolean) => void,
}) {
    const handleEnabledCheckChanged = (checked: boolean) => {
        onEnabledCheckChanged(positionNumber, colIdx, checked);
    };

    const backgroundColor = spcCheckResult == null ? nullResultsBackground
        : spcCheckResult ? 'rgba(226, 158, 11, 0.47)'
            : undefined;

    return (
        <td
            style={{ backgroundColor }}
            title={title}
            onClick={() => handleEnabledCheckChanged(!isEnabled)} // Handles cell click
        >
            <input
                type='checkbox'
                checked={isEnabled}
                onClick={(e) => e.stopPropagation()} // Prevents event bubbling
                onChange={(e) => handleEnabledCheckChanged(e.target.checked)} // Handles checkbox change
            />
        </td>
    );
}
