import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { sortBy } from '../../../../shared/utils/arrayUtils';
import { fetchProgramNameMappings } from '../../api/requests';


export default function ProgramNameMappings() {
    const [filterInput, setFilterInput] = useState('');

    const { data: programNameMappings, error, isLoading } = useQuery({
        queryKey: ['programNameMappings'],
        queryFn: () => fetchProgramNameMappings({ isHidden: false }),
        select: mappings => sortBy([...mappings], it => it.program)
    });

    const filteredProgramNameMappings = programNameMappings?.filter(it =>
        it.program.toLowerCase().includes(filterInput.toLowerCase()) ||
        it.partName.toLowerCase().includes(filterInput.toLowerCase())
    );

    const handleClearFilters = () => {
        setFilterInput('');
    }


    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>An error has occurred: {error.message}</div>;
    return (
        <div style={{ width: '1000px' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                <input
                    style={{ marginLeft: '18px' }}
                    type='text'
                    placeholder='Search'
                    value={filterInput}
                    onChange={e => setFilterInput(e.target.value)}
                />
                <button className='button--clear-filters' onClick={(e) => { e.stopPropagation(); handleClearFilters(); }}>
                    <img src='/clear-filter-icon.svg' alt='Clear filters' />
                </button>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>Program Name</th>
                        <th>Part number</th>
                        <th>Revision</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredProgramNameMappings.map(it => (
                        <tr key={it.program}>
                            <td>{it.program}</td>
                            <td>{it.partName}</td>
                            <td>{it.partRevision}</td>
                        </tr>
                    ))}
                </tbody>

            </table>
        </div>
    );
}
