import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { sortByDesc } from '../../../../shared/utils/arrayUtils';
import { fetchOperations } from '../../api/requests';


export function useFetchOperations() {
    const sevenDaysAgo = new Date(+new Date() - 7 * 24 * 3_600_000);

    const { data: pendingOperations, refetch: refetchPendingOperations } = useQuery({
        queryKey: ['operations', 'pending'],
        queryFn: () => fetchOperations({ hasPendingPotentialScrap: true }),
        select: (operations) => sortByDesc([...operations], it => it.time)
    });

    const { data: recenthandledOperations, refetch: refetchRecentHandled } = useQuery({
        queryKey: ['operations', 'handled'],
        queryFn: () => fetchOperations({ hasScrapCloseForm: true, minScrapCloseTime: sevenDaysAgo }),
    });

    // Fetch any pending label operations that might be older than 7 days
    const { data: pendingLabelOperations, refetch: refetchPendingLabel } = useQuery({
        queryKey: ['operations', 'pendingLabel'],
        queryFn: () => fetchOperations({ hasScrapLabel: true, hasScrapCloseForm: true }),
    });


    // Merge recent handled operations with pending label operations
    const handledOperations = useMemo(() => {
        if (!recenthandledOperations || !pendingLabelOperations) return null;

        const handledOperations = [...recenthandledOperations];
        for (const pendingLabelOperation of pendingLabelOperations) {
            if (!handledOperations.find(it => it._id === pendingLabelOperation._id)) {
                handledOperations.push(pendingLabelOperation);
            }
        }

        const sorted = sortByDesc([...handledOperations], it => it.scrapCloseForm.time);
        return sorted;
    }, [recenthandledOperations, pendingLabelOperations]);

    const refetchHandledOperations = () => {
        refetchRecentHandled();
        refetchPendingLabel();
    }

    const refetchAllOperations = () => {
        refetchPendingOperations();
        refetchRecentHandled();
        refetchPendingLabel();
    }


    return {
        pendingOperations,
        refetchPendingOperations,
        handledOperations,
        refetchHandledOperations,
        refetchAllOperations
    };
}
