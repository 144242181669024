import { useQuery } from '@tanstack/react-query';
import { sortBy } from '../../../../shared/utils/arrayUtils';
import { fetchLatestRecord, fetchProtocol, fetchRecordsWithProtocol } from '../../api/requests';


interface UseLoadRecordsProps {
    orderNumber: string;
}

export function useLoadRecords({
    orderNumber,
}: UseLoadRecordsProps) {

    const { data: latestRecord } = useQuery({
        queryKey: ['latestRecord', orderNumber, false],
        queryFn: async () => await fetchLatestRecord({ orderNumber, isDraft: false }),
        enabled: orderNumber != undefined,
    });
    const equipment = latestRecord?.equipment;
    const sessionStart = latestRecord?.sessionStart;

    const { data: records } = useQuery({
        queryKey: ['fetchRecordsWithProtocol', equipment, sessionStart, false],
        queryFn: async () => await fetchRecordsWithProtocol({ equipment, sessionStart, isDraft: false }),
        enabled: equipment != undefined && sessionStart != undefined,
        select: it => sortBy([...it], it => it.measurementStart),
    });

    const { data: protocol } = useQuery({
        queryKey: ['protocols', latestRecord?.protocolId],
        queryFn: async () => await fetchProtocol(latestRecord?.protocolId),
        enabled: latestRecord?.protocolId != undefined,
    });


    return {
        latestRecord,
        records,
        protocol,
        equipment,
        sessionStart,
    };
}
