import { SpcChecksArray, spcCheckPosition } from '../../../../shared/services/spc/spcCheckPosition';
import { ProtocolFinal, RecordDraft, RecordWithProtocol } from '../../types/sharedTypeImpl';


export interface DataPoint {
    value: number | null,
    valueAsString: string | null,
    partCount: number,
    measurementStart: Date,
    isInRange: boolean,
    shouldDraw: boolean,
    hasIssues: boolean,
    checkResults: SpcChecksArray,
}

type DataPointInput = Omit<DataPoint, 'hasIssues' | 'checkResults'>;


export default function transformDataPoints(
    protocol: ProtocolFinal,
    prevRecords: RecordWithProtocol[],
    currentRecord: RecordDraft | null,
    selectedPositionIdx: number,
    enabledSpcChecks?: boolean[],
    maxDataPoints?: number,
): DataPoint[] {
    const position = protocol.positions[selectedPositionIdx];

    const inputData = [...getPrevPoints(prevRecords, position.positionNumber)];
    if (currentRecord != null) inputData.push(getCurrentPoint(currentRecord, selectedPositionIdx));

    setIsInRange(inputData, maxDataPoints);
    const outputData = setSpcFlags(inputData, protocol, selectedPositionIdx, enabledSpcChecks);
    return outputData;
}

function getPrevPoints(prevRecords: RecordWithProtocol[], positionNumber: string): DataPointInput[] {
    const dataPoints: DataPointInput[] = [];
    for (const record of prevRecords) {
        if (record.isDraft) continue;

        const positionIndex = record.protocol.positions.findIndex(it => it.positionNumber === positionNumber);
        if (positionIndex === -1) continue;
        if (record.protocol.positions[positionIndex].type === 'visualInspection') continue;
        if (record.positionValues[positionIndex]?.value == null) continue;

        const valueAsString = record.positionValues[positionIndex].value as string;
        const valueAsNumber = +valueAsString;
        dataPoints.push({
            value: valueAsNumber,
            valueAsString,
            partCount: +record.partCount,
            measurementStart: record.measurementStart,
            isInRange: true,
            shouldDraw: true,
        });
    }
    return dataPoints;
}

function getCurrentPoint(currentRecord: RecordDraft, selectedPositionIdx: number): DataPointInput {
    const valueAsString = currentRecord.positionValues[selectedPositionIdx].value as string;
    const valueAsNumber = valueAsString?.length > 0 ? Number.parseFloat(valueAsString) : null;
    const shouldDraw = valueAsNumber != null && !isNaN(valueAsNumber);
    return {
        value: valueAsNumber,
        valueAsString,
        partCount: +currentRecord.partCount,
        measurementStart: currentRecord.measurementStart,
        isInRange: true,
        shouldDraw,
    }
}

function setIsInRange(dataEntries: DataPointInput[], maxDataPoints: number | undefined) {
    if (maxDataPoints == null) return;
    const minIndexInRange = dataEntries.length - maxDataPoints;
    for (let i = 0; i < minIndexInRange; i++) {
        dataEntries[i].isInRange = false;
    }
}

function setSpcFlags(
    dataEntries: DataPointInput[],
    protocol: ProtocolFinal,
    selectedPositionIdx: number,
    enabledSpcChecks?: boolean[],
): DataPoint[] {
    const realValues = dataEntries.filter(it => it.shouldDraw).map(it => it.valueAsString);
    const position = protocol.positions[selectedPositionIdx];
    const finalChecks = enabledSpcChecks ?? [true, false, false, false, false, false, false];
    const positionResults = spcCheckPosition(realValues, position, finalChecks);

    const dataPoints: DataPoint[] = [];
    for (let i = 0; i < positionResults.length; i++) {
        dataPoints.push({
            ...dataEntries[i],
            hasIssues: positionResults[i].hasIssues,
            checkResults: positionResults[i].checkResults,
        });
    }
    return dataPoints;
}
