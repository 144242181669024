import * as d3 from 'd3';
import { useEffect, useRef } from 'react';
import useResizeObserver from '../../hooks/useResizeObserver';
import { OeeRow } from '../../types/sharedTypeImpl';
import { getGraphColor, getShiftColorLight } from '../../utils/liveGraphColors';
import { OeeGraphOptions } from '../oee-graph-controls/oeeGraphOptios';
import { TransformedData, transformData } from './transformData';


interface EquipmentGraphProps {
    className?: string;
    style?: React.CSSProperties;
    equipment: string;
    equipmentDisplayName: string;
    liveTableEquipment: OeeRow[];
    hourTableEquipment: OeeRow[];
    shiftTableEquipment: OeeRow[];
    displayOptions: OeeGraphOptions;
}

export default function EquipmentGraph({
    className,
    style,
    equipment,
    equipmentDisplayName,
    liveTableEquipment,
    hourTableEquipment,
    shiftTableEquipment,
    displayOptions,
}: EquipmentGraphProps) {

    const svgRef = useRef<SVGSVGElement>();
    const dimensions = useResizeObserver(svgRef);

    useEffect(() => {
        const data = transformData(liveTableEquipment, hourTableEquipment, shiftTableEquipment, displayOptions);
        drawGraph(svgRef.current, data, equipmentDisplayName, displayOptions);
    }, [equipment, equipmentDisplayName, liveTableEquipment, hourTableEquipment, shiftTableEquipment, dimensions, displayOptions]);


    //Use relative + absolute positioning to keep the svg-s from forcing the container to grow
    return (
        <div
            className={className}
            style={{ ...style, position: 'relative' }}
        >
            <svg
                ref={svgRef}
                className={`live-graphs__graph`}
                style={{ width: '100%', height: '100%', position: 'absolute' }}
            />
        </div>
    );
}


function drawGraph(
    svg: SVGSVGElement,
    data: TransformedData,
    equipmentDisplayName: string,
    displayOptions: OeeGraphOptions,
) {
    const root = d3.select(svg);
    root.html('');

    const {
        currentPartDisplayName,
        mainData,
        shiftData, } = data;

    // Append the title element to the SVG
    root.append('text')
        .attr('x', 15)
        .attr('y', 17)
        .attr('text-anchor', 'left')
        .attr('font-family', 'Arial')
        .attr('font-size', '14px')
        .text(`${equipmentDisplayName} - ${currentPartDisplayName}`);

    // Main graph area
    const graphMargins = { top: 25, right: 0, bottom: 0, left: 0 };
    const rootRect = svg.getBoundingClientRect();
    const graphWidth = rootRect.width - graphMargins.left - graphMargins.right;
    const graphHeight = rootRect.height - graphMargins.top - graphMargins.bottom;
    const graph = root.append('g').attr('transform', `translate(${graphMargins.left}, ${graphMargins.top})`);

    // Create x and y scales
    const minBarHeight = 0.075;
    const x = d3
        .scaleBand<number>()
        .range([0, graphWidth])
        .domain(data.mainData.map(d => +d.start));//Use domain rather than scale, so we can remove sections
    const y = d3
        .scaleLinear()
        .range([graphHeight, 0])
        .domain([0 - minBarHeight, 1]);

    // Create shift background
    for (const shift of shiftData) {
        graph.append('rect')
            .attr('x', x(+shift.firstMainEntry.start))
            .attr('y', 0)
            .attr('width', x(+shift.lastMainEntry.start) - x(+shift.firstMainEntry.start) + x.bandwidth() + 1)
            .attr('height', graphHeight)
            .attr('fill', getShiftColorLight(shift.shift));
    }

    // Append the bar graph
    mainData.forEach(dataPoint => {
        graph.append('rect')
            .attr('x', x(+dataPoint.start))
            .attr('y', y(dataPoint.rollingAverageValue))
            .attr('width', x.bandwidth())
            .attr('height', graphHeight - y(dataPoint.rollingAverageValue))
            .attr('fill', getGraphColor(dataPoint, displayOptions));
    });


    // Show average shift values as a dashed line
    for (const shift of shiftData) {
        if (shift.value == 0) continue;
        graph.append('line')
            .attr('x1', x(+shift.firstMainEntry.start))
            .attr('y1', y(shift.value) + 0.5)
            .attr('x2', x(+shift.lastMainEntry.start) + x.bandwidth())
            .attr('y2', y(shift.value) + 0.5)
            .attr('stroke', 'black')
            .attr('stroke-dasharray', '5,5')
            .attr('stroke-width', 1.0);
    }

    // Show average shift values as text
    for (const shift of shiftData) {
        const text = graph.append('text')
            .attr('x', (x(+shift.lastMainEntry.start) + x(+shift.firstMainEntry.start) + x.bandwidth()) / 2)
            .attr('y', y(minBarHeight))
            .attr('text-anchor', 'middle')
            .attr('font-size', `${14}px`)
            .text(shift.label);

        const bbox = text.node().getBBox();
        graph.insert('rect') // Text background
            .attr('x', bbox.x - 1)
            .attr('y', bbox.y - 1)
            .attr('width', bbox.width + 2)
            .attr('height', bbox.height + 2)
            .attr('opacity', 0.75)
            .attr('fill', getShiftColorLight(shift.shift));
        text.raise();
    }

    // Create vertical separator lines, if there's only one shift type
    if (displayOptions.visibleShifts.length === 1) {
        for (const shift of shiftData) {
            const xPos = Math.floor(x(+shift.lastMainEntry.start) + x.bandwidth()) + 0.5;//Prevents blurred lines
            graph.append('line')
                .attr('x1', xPos)
                .attr('y1', 0)
                .attr('x2', xPos)
                .attr('y2', graphHeight)
                .attr('stroke', '#666')
                .attr('stroke-width', 0.5);
        }
    }
}
