import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { CmmReport, CmmReportWithFile } from '../../../shared/types/cmmReportInfo';
import { sortByDesc } from '../../../shared/utils/arrayUtils';
import { archiveCmmReports, fetchCmmReportFile, fetchCmmReports, fetchCmmReportsWithFiles } from '../api/requests';
import importCmmFiles, { CMM_FILE_EXTENSIONS } from '../services/cmm-import/importCmmFiles';
import { ImportFileResult, ImportValue } from '../services/cmm-import/types';
import { ProtocolFinal, RecordDraft } from '../types/sharedTypeImpl';
import shouldArchiveCmmReports from '../utils/shouldArchiveCmmReports';
import CmmImportDialog from './cmmImportDialog';
import CmmReportListDialog from './cmmReportListDialog';


interface CmmImportButtonsProps {
    style?: React.CSSProperties;
    protocol: ProtocolFinal;
    record: RecordDraft;
    setRecord: (record: RecordDraft) => void;
    setIsDirty: (isDirty: boolean) => void;
}

export default function CmmImportButtons({
    style,
    protocol,
    record,
    setRecord,
    setIsDirty,
}: CmmImportButtonsProps) {
    const [importDialogData, setImportDialogData] = useState<ImportFileResult[] | null>(null);
    const [reportSelectDialogData, setReportSelectDialogData] = useState<CmmReport[] | null>(null);
    const [isAutoImporting, setIsAutoImporting] = useState(false);
    const [isManualImporting, setIsManualImporting] = useState(false);
    const fileInputRef = useRef(null);


    const handleImportLocalClick = () => {
        fileInputRef.current.click();
    };

    const handleLocalFilesSelected = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files);
        const importResults = await importCmmFiles(protocol, record, files, 'local');
        setImportDialogData(importResults);
    };

    const handleAutoImportClick = async () => {
        if (isAutoImporting || isManualImporting) return;
        try {
            setIsAutoImporting(true);
            if (!record.orderNumber) {
                alert('Record must have order number to import automatically');
                return;
            }

            const cmmReports = await fetchCmmReportsWithFiles(record.orderNumber);
            if (cmmReports.length === 0) {
                alert(`No CMM reports found (looking for files in sharepoint that begin with "${record.orderNumber}")`);
                return;
            }

            const filteredByPartCount = cmmReports.filter(it => containsToken(it.name, record.partCount.toString()));
            const reportsFinal = filteredByPartCount.length > 0 ? filteredByPartCount : cmmReports;
            sortByDesc(reportsFinal, it => it.lastModified);

            const files = reportsFinal.map(it => it.file);
            const importResults = await importCmmFiles(protocol, record, files, 'sharepoint');

            if (shouldImportWithoutDialog(reportsFinal, importResults)) {
                importWithoutDialog(reportsFinal, importResults);
            } else {
                setImportDialogData(importResults);
            }
        } catch (error) {
            alert('Failed to fetch CMM reports');
        } finally {
            setIsAutoImporting(false);
        }
    }

    const containsToken = (str: string, token: string) => {
        // Token must be surrounded by non-word characters, an underscore or start/end of string
        const regex = new RegExp(`(\\W|_|^)${token}(\\W|_|$)`, 'i');
        return regex.test(str);
    }

    const handleManualImportClick = async () => {
        if (isAutoImporting || isManualImporting) return;
        try {
            setIsManualImporting(true);
            const cmmReports = await fetchCmmReports();
            sortByDesc(cmmReports, it => it.lastModified);
            setReportSelectDialogData(cmmReports);
        } catch (error) {
            alert('Failed to fetch CMM reports');
        } finally {
            setIsManualImporting(false);
        }
    }

    const handleReportSelectDialogSubmit = async (names: string[]) => {
        try {
            const files = await Promise.all(names.map(fetchCmmReportFile));
            const importResults = await importCmmFiles(protocol, record, files, 'sharepoint');
            setImportDialogData(importResults);
            setReportSelectDialogData(null);
        } catch (error) {
            alert('Failed to fetch CMM reports');
        }
    };

    const handleReportSelectDialogCancel = () => {
        setReportSelectDialogData(null);
    };

    const handleImportDialogSubmit = (importValues: ImportValue[], sharePointFiles: string[]) => {
        applyImportedValues(importValues);
        archiveImportedFiles(sharePointFiles);
        setImportDialogData(null);
    };

    const handleImportDialogCancel = () => {
        setImportDialogData(null);
    };

    const shouldImportWithoutDialog = (cmmReports: CmmReportWithFile[], importResults: ImportFileResult[]) => {
        if (cmmReports.length !== 1 || importResults.length !== 1) {
            return false;
        }
        const partCountMatches = record.partCount && containsToken(cmmReports[0].name, record.partCount.toString());
        const hasNoIssues = importResults[0].isIncluded && importResults[0].positions.every(it => it.isIncluded);
        return partCountMatches && hasNoIssues;
    }

    const importWithoutDialog = (cmmReports: CmmReportWithFile[], importResults: ImportFileResult[]) => {
        if (cmmReports.length !== 1 || importResults.length !== 1) {
            throw new Error('There should be exactly one CMM report and one import result');
        }

        const cmmReport = cmmReports[0];
        const importResult = importResults[0];

        const importValues = importResult.positions.map(it => ({
            position: it.position,
            value: it.value,
            source: importResult.valueSource,
            sourceSubType: importResult.valueSourceSubType,
        }));

        applyImportedValues(importValues);
        archiveImportedFiles([cmmReport.name]);

        toast.success('CMM report imported successfully');
    }

    const applyImportedValues = (importValues: ImportValue[]) => {
        const newPositionValues = [...record.positionValues];
        for (const importValue of importValues) {
            const positionIdx = protocol.positions.findIndex(it => it.positionNumber === importValue.position);
            newPositionValues[positionIdx] = {
                value: importValue.value,
                source: importValue.source,
                sourceSubType: importValue.sourceSubType,
            }
        }

        setRecord({ ...record, positionValues: newPositionValues });
        setIsDirty(true);
    };

    const archiveImportedFiles = (sharePointFiles: string[]) => {
        if (!shouldArchiveCmmReports()) return;
        if (sharePointFiles == null || sharePointFiles.length === 0) return;
        try {
            archiveCmmReports(sharePointFiles);
        } catch (error) {
            alert('Failed to archive some imported files');
        }
    }



    return (
        <div style={{ ...style, display: 'flex', flexDirection: 'row', gap: '4px' }}>
            <button
                type='button'
                onClick={handleAutoImportClick}
                disabled={isAutoImporting}
            >
                Auto-import
            </button>

            <button
                type='button'
                onClick={handleManualImportClick}
                disabled={isManualImporting}
            >
                Manual import
            </button>

            <button
                type='button'
                onClick={handleImportLocalClick}
            >
                Local file import
            </button>
            <input
                type='file'
                accept={CMM_FILE_EXTENSIONS.join(',')}
                multiple
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleLocalFilesSelected}
                tabIndex={-1}
            />

            {importDialogData && (
                <CmmImportDialog
                    isOpen={true}
                    importResult={importDialogData}
                    onCancel={handleImportDialogCancel}
                    onSubmit={handleImportDialogSubmit}
                />
            )}
            {reportSelectDialogData && (
                <CmmReportListDialog
                    isOpen={true}
                    cmmReports={reportSelectDialogData}
                    initialFilter={protocol.partName}
                    onCancel={handleReportSelectDialogCancel}
                    onSubmit={handleReportSelectDialogSubmit}
                />
            )}
        </div>
    );
}
