import { useEffect, useRef } from 'react';
import { Protocol, RecordWithProtocol } from '../../types/sharedTypeImpl';
import { drawSigmaHistogram } from './drawSigmaHistogram';
import getHistogramData from './getHistogramData';


interface SigmaHistogramProps {
    className?: string,
    style?: React.CSSProperties,
    protocol: Protocol,
    records: RecordWithProtocol[],
    activePositionIdx: number,
    numLatest: number | null,
}

export default function SigmaHistogram({
    className,
    style,
    protocol,
    records,
    activePositionIdx,
    numLatest,
}: SigmaHistogramProps) {

    const defaultContainerRef = useRef<HTMLDivElement>();

    //Draw default graph
    useEffect(() => {
        const data = getHistogramData(protocol, records, activePositionIdx, numLatest);
        drawSigmaHistogram(defaultContainerRef.current, data);
    }, [protocol, records, activePositionIdx, numLatest]);


    return (
        <div
            ref={defaultContainerRef}
            className={className}
            style={style}
        />
    )
}
