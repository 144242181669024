import { Position } from '../types/protocol.js';
import { getCountMap } from './getCountMap.js';
import { getNumberStringPrecision } from './getNumberStringPrecision.js';


export function getPositionPrecision(position: Position): number {
    const nominalPrecision = getNumberStringPrecision(position.nominal!);
    const upperTolerancePrecision = getNumberStringPrecision(position.upperTolerance!);
    const lowerTolerancePrecision = getNumberStringPrecision(position.lowerTolerance!);
    const precision = Math.max(nominalPrecision, upperTolerancePrecision, lowerTolerancePrecision, 2);
    return precision;
}

export function getPositionValuesPrecision(values: string[]): number {
    const precisionCounts = getCountMap(values, getNumberStringPrecision);
    const mostCommonPrecision = getMaxValueKey(precisionCounts, (value, key) => key >= 2);
    return !mostCommonPrecision || mostCommonPrecision < 2
        ? 2
        : mostCommonPrecision;
}


function getMaxValueKey(
    map: Map<number, number>,
    filterFn: (value: number, key: number) => boolean
): number | undefined {
    let maxValue: number = -Infinity;
    let maxValueKey: number | undefined = undefined;
    for (const [key, value] of map) {
        if (filterFn(value, key) && value > maxValue) {
            maxValueKey = key;
            maxValue = value;
        }
    }
    return maxValueKey;
}
