import { ProtocolBase } from '../../types/protocol.js';
import { RecordWithProtocolBase } from '../../types/recordWithProtocol.js';
import { spcChecksAggregated } from './spcChecksAggregated.js';


interface IssuesCount {
    enabledIssues: number;
    allIssues: number;
}


export function countSpcIssues<ObjectIdType = string>(
    protocol: ProtocolBase<ObjectIdType>,
    records: RecordWithProtocolBase<ObjectIdType>[],
    enabledSpcChecks: Map<string, boolean[]>,
    numLatest: number | null,
): IssuesCount {
    const spcChecksTable = spcChecksAggregated(protocol, records, numLatest);

    let enabledIssues = 0;
    let allIssues = 0;
    for (const { position, checks } of spcChecksTable) {
        const enabledChecks = enabledSpcChecks.get(position.positionNumber)!;
        for (const [checkIndex, isEnabled] of enabledChecks.entries()) {
            const isIssue = checks[checkIndex];
            if (isIssue) allIssues++;
            if (isEnabled && isIssue) enabledIssues++;
        }
    }

    return {
        enabledIssues,
        allIssues,
    }
}
