import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LinkButton from '../../components/linkButton';
import { Record, RecordFinal } from '../../types/sharedTypeImpl';
import { formatDatetime } from '../../utils/fieldFormats';
import RecordContextMenu, { RecordContextMenuState } from './recordContextMenu';


interface RecordListTableProps {
    isLoading: boolean;
    error: any;
    records: Record[];
    onRecordDelete: (record: Record) => void;
    onRecordsExport: (record: Record) => void;
    onSearchTextChange: (searchText: string) => void;
}

export default function RecordListTable({
    isLoading,
    error,
    records,
    onRecordDelete,
    onRecordsExport,
    onSearchTextChange,
}: RecordListTableProps) {
    const [menuProps, setMenuProps] = useState<RecordContextMenuState | undefined>(undefined);

    const navigate = useNavigate();

    function handleMenuOpen(e: React.MouseEvent, record: Record) {
        e.preventDefault();
        setMenuProps({ record, screenCoords: { x: e.pageX, y: e.pageY } });
    }

    function handleEditSelected(record: Record) {
        navigate(`/record-view?id=${record._id}&mode=edit`);
    }


    if (!records && isLoading) return <div>Loading...</div>;
    if (error) return <div>An error has occurred: {error.message}</div>;
    return (
        <>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Equipment</th>
                        <th>Part number</th>
                        <th>Version</th>
                        <th>Order number</th>
                        <th>Part count</th>
                        <th>Measurement time</th>
                        <th>User</th>
                        <th>Status</th>
                        <th>Issues</th>
                        <th />
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {records?.map(it =>
                        <tr key={it._id}>
                            <td>
                                <Link className='link-button--non-intrusive' to={`/machine-log#equipment=${it.equipment}`}>
                                    {it.equipmentDisplayName}
                                </Link>
                            </td>
                            <CellWithSearchLink text={it.protocolPartDisplayName} onSearchTextChange={onSearchTextChange} />
                            <td>{it.protocolVersion}</td>
                            <CellWithSearchLink text={it.orderNumber} onSearchTextChange={onSearchTextChange} />
                            <td>{it.partCount}</td>
                            <td>{formatDatetime(it.measurementStart)}</td>
                            <td>{it.user}</td>
                            <td>{it.isDraft ? 'Draft' : ''}</td>
                            <Issues record={it} />
                            <td>{it.isDraft
                                ? <Link to={`/record-view?id=${it._id}&mode=edit`} className='a__button'>Continue</Link>
                                : <Link to={`/record-view?id=${it._id}`} className='a__button'>View</Link>
                            }</td>
                            <td>
                                <LinkButton onClick={(e) => { handleMenuOpen(e, it) }} >
                                    Options
                                </LinkButton>
                            </td>
                        </tr >
                    )}
                </tbody>
            </table>

            {menuProps && <RecordContextMenu
                record={menuProps.record}
                screenCoords={menuProps.screenCoords}
                onDeleteSelected={onRecordDelete}
                onEditSelected={handleEditSelected}
                onExportSelected={onRecordsExport}
                onClose={() => setMenuProps(undefined)}
            />}
        </>
    );
}

function CellWithSearchLink({
    text,
    onSearchTextChange
}: { text: string, onSearchTextChange: (searchText: string) => void }) {
    return (
        <td>
            <span className='link-button--non-intrusive' onClick={() => onSearchTextChange(text)}>
                {text}
            </span>
        </td>
    );
}

function Issues({ record }: { record: Record }) {
    const numIssues = !record.isDraft
        ? (record as RecordFinal).numIssues
        : undefined;
    const highlight = numIssues && numIssues > 0;

    return (
        <td className={highlight ? 'position-field--out-of-spec' : ''}>
            {numIssues}
        </td>
    );
}
