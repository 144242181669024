import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Tool } from '../../../../shared/types/operation';
import Input from '../input';
import { OpSubFormProps, OpSubFormRef } from './operationSubForm';


export const CollateralDamage = forwardRef<OpSubFormRef, OpSubFormProps>(({
    operation,
    onOperationChange,
}: OpSubFormProps, ref) => {
    const toolSubformRefs = useRef(new Map<number, CollateralDamageRef>());

    const handleAddTool = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();//Prevents field validation from triggering due to automatic focus shifts

        const newTool = { toolVariant: '', toolNumber: '' };
        onOperationChange({
            ...operation,
            collateralDamage: [...(operation.collateralDamage ?? []), newTool]
        });
    };

    const setRef = (key: number, ref: CollateralDamageRef) => {
        if (ref) toolSubformRefs.current.set(key, ref);
        else toolSubformRefs.current.delete(key);
    }

    useImperativeHandle(ref, () => ({
        validateWarnAndGet: (result) => {
            const keys = Array.from(toolSubformRefs.current.keys()).sort();
            for (const key of keys) {
                if (!toolSubformRefs.current.get(key).validateAndWarn()) return false;
            }

            result.collateralDamage = operation.collateralDamage;
            return true;
        }
    }));


    return (
        <div
            className='dialog-form__field--with-margins'
            style={{ border: '1px solid #ccc' }}
        >

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span style={{ marginLeft: '10px' }}>
                    Collateral damage
                </span>
                <button
                    className='button--large'
                    onClick={(e) => handleAddTool(e)}
                >
                    Add tool
                </button>
            </div>

            {operation.collateralDamage?.map((tool, index) => (
                <CollateralDamageTool
                    key={index}
                    ref={(r) => setRef(index, r)}
                    index={index}
                    tool={tool}
                    onToolChange={(newTool) => {
                        const newCollateralDamage = [...operation.collateralDamage];
                        newCollateralDamage[index] = newTool;
                        onOperationChange({ ...operation, collateralDamage: newCollateralDamage });
                    }}
                    onDelete={(index) => {
                        const newCollateralDamage = [...operation.collateralDamage];
                        newCollateralDamage.splice(index, 1);
                        onOperationChange({ ...operation, collateralDamage: newCollateralDamage });
                    }}
                />
            ))}
        </div>
    );
});


interface CollateralDamageRef {
    validateAndWarn: () => boolean;
}

interface CollateralDamageToolProps {
    index: number;
    tool: Tool;
    onToolChange: (tool: Tool) => void;
    onDelete: (index: number) => void;
}

export const CollateralDamageTool = forwardRef<CollateralDamageRef, CollateralDamageToolProps>(({
    index,
    tool,
    onToolChange,
    onDelete,
}: CollateralDamageToolProps, ref) => {
    const toolNumberRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
        validateAndWarn
    }));

    const validateAndWarn = () => {
        const errorMessage = validate();
        if (errorMessage) {
            toolNumberRef.current?.setCustomValidity(errorMessage);
            toolNumberRef.current?.reportValidity();
            return false;
        } else {
            return true;
        }
    }

    const validate = () => {
        if (tool.toolNumber?.substring(1).length !== 4) {
            return 'Tool number must have length 4';
        }
        return null;
    };

    const handleToolNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        toolNumberRef.current?.setCustomValidity('');
        onToolChange({ ...tool, toolNumber: 'T' + event.target.value });
    }


    const name = 'collateral_tool_variant_' + index;
    return (
        <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #ccc' }}>
            <fieldset style={{ border: 'none', }}>
                <label>
                    <input
                        type='radio'
                        name={name}
                        value='Main'
                        required
                        checked={tool.toolVariant === 'Main'}
                        onChange={(e) => onToolChange({ ...tool, toolVariant: e.target.value })}
                    />
                    Main
                </label>
                <label style={{ marginLeft: '0.4rem' }}>
                    <input
                        type='radio'
                        name={name}
                        value='Sub'
                        required
                        checked={tool.toolVariant === 'Sub'}
                        onChange={(e) => onToolChange({ ...tool, toolVariant: e.target.value })}
                    />
                    Sub
                </label>
            </fieldset>

            <div className='dialog-form__field--with-margins' style={{ marginLeft: '0.8rem' }}>
                <label>Tool number</label>
                <Input
                    ref={toolNumberRef}
                    style={{ marginLeft: '0.4rem' }}
                    type='number'
                    step='1'
                    min='0'
                    required
                    value={tool.toolNumber.substring(1)}
                    onChange={handleToolNumberChange}
                />
            </div>

            <span className='collateral-damange__delete' onClick={() => onDelete(index)}>
                &times;
            </span>
        </div>
    );
});
