

interface LineStyle {
    color: string,
    dashArray?: string,
}

const lineStyles: { [key: string]: LineStyle } = {
    nominal: { color: '#000000' },
    tolerance: { color: '#46A346' },
    mean: { color: '#46A346', dashArray: '8,8' },
    sigma1: { color: '#52a3ff', dashArray: '4,4' },
    sigma2: { color: '#52a3ff', dashArray: '8,8' },
    sigma3: { color: '#52a3ff', dashArray: '20,6' },
};
export default lineStyles;
