import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { fetchLiveGraphsData } from '../../api/requests';
import EquipmentGraph from '../../components/equipment-oee-graph/equipmentGraph';
import NavBar from '../../components/navBar';
import OeeGraphControls from '../../components/oee-graph-controls/oeeGraphControls';
import { OeeGraphOptions } from '../../components/oee-graph-controls/oeeGraphOptios';
import { useFullScreenToggle } from '../../hooks/useFullscreenToggle';
import getEquipmentCoords from '../../utils/getEquipmentCoords';
import FactoryGraph from './factoryGraph';
import { Availability } from './graphValueType';
import groupPerEquipment, { LiveDataPerEquipment } from './groupPerEquipment';
import HoursGraph from './hoursGraph';


export default function LiveGraphs() {
    const { isFullscreen } = useFullScreenToggle();

    return (
        <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            {!isFullscreen && <NavBar />}
            <div className='content' style={{ flex: '1 1' }}>
                <LiveGraphsContent />
            </div>
        </div>
    )
}


function LiveGraphsContent() {
    const [liveDataPerEquipment, setLiveDataPerEquipment] = useState<Map<string, LiveDataPerEquipment>>();
    const [displayOptions, setDisplayOptions] = useState<OeeGraphOptions>({
        visibleShifts: [
            'morning',
            'evening',
            'night',
        ],
        timeFrameDays: 1,
        excludeWeekends: false,
        smoothingAmount: 1,
        resolution: 'halfHour',
        valueType: Availability,
    });

    const liveDataAllEquipmentsRows = liveDataPerEquipment?.get('All equipments');


    const { data: liveData } = useQuery({
        queryKey: ['liveGraphsData'],
        queryFn: fetchLiveGraphsData,
        refetchInterval: 60_000,
    });

    useEffect(() => {
        if (!liveData) return;
        setLiveDataPerEquipment(groupPerEquipment(liveData));
    }, [liveData]);


    if (!liveData || !liveDataPerEquipment) return 'Loading...';
    return (
        <div className='live-graphs__root'>
            <div className='live-graphs__top_bar'>
                <HoursGraph
                    className='live-graphs__top_bar_item'
                    shiftTable={liveData?.shiftTable ?? []}
                    liveTable={liveData?.liveTable ?? []}
                    displayOptions={displayOptions}
                />
                <EquipmentGraph
                    className='live-graphs__top_bar_item'
                    equipment='All equipments'
                    equipmentDisplayName={liveDataAllEquipmentsRows?.displayName ?? ''}
                    liveTableEquipment={liveDataAllEquipmentsRows?.liveTable ?? []}
                    hourTableEquipment={liveDataAllEquipmentsRows?.hourTable ?? []}
                    shiftTableEquipment={liveDataAllEquipmentsRows?.shiftTable ?? []}
                    displayOptions={displayOptions}
                />
                <FactoryGraph
                    className='live-graphs__top_bar_item'
                    shiftTable={liveData?.shiftTable ?? []}
                    graphValueType={displayOptions.valueType}
                    displayOptions={displayOptions}
                />
            </div>

            <div className='live-graphs__equipments-grid equipment-grid'>
                <OeeGraphControls
                    style={{
                        gridColumn: `col1 / span 2`,
                        gridRow: '1 / span 2',
                    }}
                    shitOptionsOnly={false}
                    oeeGraphOptions={displayOptions}
                    setOeeGraphOptions={setDisplayOptions}
                />

                {Array.from(liveDataPerEquipment.entries())
                    .filter(([equipment]) => equipment !== 'All equipments')
                    .map(([equipment, data]) => {
                        const { row, col } = getEquipmentCoords(equipment);

                        return (
                            <EquipmentGraph
                                key={equipment}
                                style={{
                                    gridColumn: `col${col + 1}`,
                                    gridRow: row + 1,
                                }}
                                equipment={equipment}
                                equipmentDisplayName={data.displayName}
                                liveTableEquipment={data.liveTable}
                                hourTableEquipment={data.hourTable}
                                shiftTableEquipment={data.shiftTable}
                                displayOptions={displayOptions}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}
