import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { fetcMeasurementIntervalTable } from '../../api/requests';
import NavBar from '../../components/navBar';
import ProgramNameMappingDialog from '../../components/program-name-mapping/programNameMappingDialog';
import { Measurement, MeasurementIntervalState, Record } from '../../types/sharedTypeImpl';
import { encodeQueryData } from '../../utils/fetchRequests';
import { openLinkInNewTab } from '../../utils/navigation';
import BatchDetailsDialog from './batchDetailsDialog';
import MeasurementDialog from './measurementDialog';
import measurementIntervalSort from './measurementIntervalSort';
import MeasurementIntervalTable from './measurementIntervalTable';


export default function MeasurementInterval() {
    const [pendingMeasurementRow, setPendingMeasurementRow] = useState<MeasurementIntervalState | null>(null);
    const [pendingBatchDetailsRow, setPendingBatchDetailsRow] = useState<MeasurementIntervalState | null>(null);
    const [showMappingsDialog, setShowMappingsDialog] = useState(false);

    const isDialogOpen = pendingMeasurementRow != null || pendingBatchDetailsRow != null || showMappingsDialog;

    const { data, error, refetch } = useQuery({
        queryKey: ['measurementIntervalTable'],
        queryFn: async () => {
            const table = await fetcMeasurementIntervalTable();
            measurementIntervalSort(table);
            return table;
        },
        enabled: !isDialogOpen,
        refetchInterval: 1_000,
    });


    function handlePartCountClicked(row: MeasurementIntervalState) {
        if (!row.equipment || (!row.inOfflineMode && !row.product)) {
            return;
        }

        setPendingBatchDetailsRow(row);
    }

    function handleMeasurePressed(row: MeasurementIntervalState) {
        if (!row.equipment || (!row.inOfflineMode && !row.product)) {
            return;
        }

        setPendingMeasurementRow(row);
    }

    async function onMeasurementAdded(measurement: Measurement, record: Record | undefined) {
        setPendingMeasurementRow(null);
        refetch();

        if (record != null) {
            openLinkInNewTab(`record-view` + encodeQueryData({ id: record._id, mode: 'edit' }));
        }
    }


    return (<>
        <NavBar />
        <div className='content' style={{marginTop: '12px'}}>
            <div className='flex--horz'>
                <p id='error'>{error?.message}</p>
                <div className='flex__spacer' />
                <ShowMappingsButton setShowMappingsDialog={setShowMappingsDialog} />
            </div>

            <MeasurementIntervalTable
                data={data}
                handlePartCountClicked={handlePartCountClicked}
                handleMeasurePressed={handleMeasurePressed}
            />
        </div>
        <MeasurementDialog
            isOpen={pendingMeasurementRow != null}
            equipment={pendingMeasurementRow?.equipment}
            equipmentDisplayName={pendingMeasurementRow?.displayName}
            product={pendingMeasurementRow?.product}
            onMeasurementAdded={onMeasurementAdded}
            onClose={() => setPendingMeasurementRow(null)}
        />
        <BatchDetailsDialog
            isOpen={pendingBatchDetailsRow != null}
            measureIntervalRow={pendingBatchDetailsRow}
            onClose={() => setPendingBatchDetailsRow(null)}
        />

        {showMappingsDialog &&
            <ProgramNameMappingDialog onClose={() => setShowMappingsDialog(false)} />
        }
    </>);
}

function ShowMappingsButton({
    setShowMappingsDialog,
}: {
    setShowMappingsDialog: (value: boolean) => void
}) {
    return (
        <button
            style={{ marginBottom: '8px' }}
            onClick={() => setShowMappingsDialog(true)}
        >
            Show mappings
        </button>
    );
}
