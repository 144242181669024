import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortByDesc } from '../../../../shared/utils/arrayUtils';
import { fetchProtocols, patchProtocol } from '../../api/requests';
import NavBar from '../../components/navBar';
import ProgramNameMappingDialog from '../../components/program-name-mapping/programNameMappingDialog';
import { Protocol } from '../../types/sharedTypeImpl';
import { encodeQueryData } from '../../utils/fetchRequests';
import ProtocolListInputs from './protocolListInputs';
import ProtocolTable from './protocolTable';


export default function ProtocolList() {
    const [filterInput, setFilterInput] = useState('');
    const [showDisabled, setShowDisabled] = useState(false);
    const [filteredProtocols, setFilteredProtocols] = useState<Protocol[] | undefined>(undefined);
    const [showMappingsDialog, setShowMappingsDialog] = useState(false);

    const { data: rawProtocols, error, isLoading, refetch } = useQuery({
        queryKey: ['protocols'],
        queryFn: fetchProtocols,
        select: protocols => sortByDesc(protocols, it => it.createTime)
    });


    const navigate = useNavigate();

    useEffect(() => {
        const filter = filterInput.toLowerCase().trim();
        const filtered = rawProtocols
            ?.filter(it => showDisabled || !it.isDisabled)
            ?.filter(it =>
                it.partDisplayName?.toLowerCase().includes(filter)
                || it.programs?.some(it => it.toLowerCase().includes(filter))
            );
        setFilteredProtocols(filtered);
    }, [rawProtocols, filterInput, showDisabled]);


    const handleSetEnabled = async (protocol: Protocol, isDisabled: boolean) => {
        const success = await patchProtocol(protocol._id, { isDisabled });
        if (success) refetch();
        else alert('Failed to set enabled status');
    }

    const handleProtocolCopy = (protocol: Protocol) => {
        navigate('/protocol-view' + encodeQueryData({ baseProtocolId: protocol._id, isCopy: true, mode: 'edit' }));
    }

    const handleShowMappingsClick = () => {
        setShowMappingsDialog(true);
    }


    return (
        <>
            <NavBar />
            <div className='content' style={{ width: '1380px' }}>
                <ProtocolListInputs
                    isLoading={isLoading}
                    error={error}
                    showDisabled={showDisabled}
                    filterInput={filterInput}
                    setShowDisabled={setShowDisabled}
                    setFilterInput={setFilterInput}
                    onShowMappingsClick={handleShowMappingsClick}
                />
                <ProtocolTable
                    protocols={filteredProtocols}
                    onProtocolSetDisabled={handleSetEnabled}
                    onProtocolCopy={handleProtocolCopy}
                    onSearchTextChange={setFilterInput}
                />
            </div>

            {showMappingsDialog &&
                <ProgramNameMappingDialog onClose={() => setShowMappingsDialog(false)} />
            }
        </>
    );
}
