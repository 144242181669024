import { OeeGraphOptions } from './oeeGraphOptios';


interface OeeGraphControlsProps {
    style?: React.CSSProperties;
    shitOptionsOnly?: boolean;
    oeeGraphOptions: OeeGraphOptions;
    setOeeGraphOptions: (oeeGraphOptions: OeeGraphOptions) => void;
}

export default function OeeGraphControls({
    style,
    shitOptionsOnly,
    oeeGraphOptions,
    setOeeGraphOptions,
}: OeeGraphControlsProps) {

    const handleShiftSelectionChange = (shift: string, isEnabled: boolean) => {
        const visibleShifts = isEnabled
            ? [...oeeGraphOptions.visibleShifts, shift]
            : oeeGraphOptions.visibleShifts.filter(s => s !== shift);

        setOeeGraphOptions({ ...oeeGraphOptions, visibleShifts });
    }


    const isTimeFrameVisible = !shitOptionsOnly;
    const isSmoothingVisible = !shitOptionsOnly;
    const isResolutionVisible = !shitOptionsOnly;
    const isValueTypeVisible = !shitOptionsOnly;

    if (isTimeFrameVisible && oeeGraphOptions.timeFrameDays === undefined
        || isSmoothingVisible && oeeGraphOptions.smoothingAmount === undefined
        || isResolutionVisible && oeeGraphOptions.resolution === undefined
        || isValueTypeVisible && oeeGraphOptions.valueType === undefined
    ) {
        throw new Error('OeeGraphControls: Missing required options');
    }


    return (
        <div
            className='flex--vert'
            style={{
                ...style,
                gap: '12px',
            }}
        >
            <div className='flex--horz' style={{ gap: '20px' }}>
                <label>
                    <input
                        style={{ marginRight: '8px' }}
                        type='checkbox'
                        checked={oeeGraphOptions.visibleShifts.includes('morning')}
                        onChange={e => handleShiftSelectionChange('morning', e.target.checked)}
                    />
                    Morning
                </label>
                <label>
                    <input
                        style={{ marginRight: '8px' }}
                        type='checkbox'
                        checked={oeeGraphOptions.visibleShifts.includes('evening')}
                        onChange={e => handleShiftSelectionChange('evening', e.target.checked)}
                    />
                    Evening
                </label>
                <label>
                    <input
                        style={{ marginRight: '8px' }}
                        type='checkbox'
                        checked={oeeGraphOptions.visibleShifts.includes('night')}
                        onChange={e => handleShiftSelectionChange('night', e.target.checked)}
                    />
                    Night
                </label>
            </div>

            <div className='flex--horz' style={{ gap: '12px' }}>
                <label>
                    <input
                        style={{ marginRight: '8px' }}
                        type='checkbox'
                        checked={oeeGraphOptions.excludeWeekends}
                        onChange={e => setOeeGraphOptions({ ...oeeGraphOptions, excludeWeekends: e.target.checked })}
                    />
                    Exclude weekends
                </label>
            </div>

            {isTimeFrameVisible &&
                <div className='flex--horz' style={{ gap: '12px' }}>
                    <label htmlFor='slider'>Time frame length</label>
                    <input
                        id='slider'
                        type='range'
                        min={1}
                        max={7}
                        step={1}
                        value={oeeGraphOptions.timeFrameDays}
                        onChange={(e) => setOeeGraphOptions({ ...oeeGraphOptions, timeFrameDays: +e.target.value })}
                    />
                    <span>{oeeGraphOptions.timeFrameDays} days</span>
                </div>
            }

            {isSmoothingVisible &&
                <div className='flex--horz' style={{ gap: '12px' }}>
                    <label htmlFor='smoothing'>Smoothing amount</label>
                    <input
                        id='smoothing'
                        type='range'
                        min={0}
                        max={2}
                        step={1}
                        value={oeeGraphOptions.smoothingAmount}
                        onChange={(e) => setOeeGraphOptions({ ...oeeGraphOptions, smoothingAmount: +e.target.value })}
                    />
                    <span>{oeeGraphOptions.smoothingAmount} bars</span>
                </div>
            }

            {isResolutionVisible &&
                <div className='flex--horz' style={{ gap: '12px' }}>
                    <label htmlFor='resolution'>Resolution</label>
                    <select
                        id='resolution'
                        value={oeeGraphOptions.resolution}
                        onChange={(e) => setOeeGraphOptions({ ...oeeGraphOptions, resolution: e.target.value as any })}
                    >
                        <option value='halfHour'>Half hour</option>
                        <option value='hour'>Hour</option>
                    </select>
                </div>
            }

            {isValueTypeVisible &&
                <div className='flex--horz' style={{ gap: '12px' }}>
                    <label htmlFor='valueType'>Value type</label>
                    <select
                        id='valueType'
                        value={oeeGraphOptions.valueType}
                        onChange={(e) => setOeeGraphOptions({ ...oeeGraphOptions, valueType: e.target.value as any })}
                    >
                        <option value='availability'>Availability</option>
                        <option value='quality'>Quality</option>
                        <option value='scraps'>Scraps</option>
                        <option value='performance'>Performance</option>
                        <option value='utilization'>Utilization</option>
                        <option value='oee'>OEE</option>
                        <option value='oeeWithoutSetup'>OEE without setup</option>
                        <option value='teep'>TEEP</option>
                    </select>
                </div>
            }
        </div>
    )
}
