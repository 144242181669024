import { FixedLengthArray } from '../../types/fixedLengthArray.js';
import { Position } from '../../types/protocol.js';
import { maxBy } from '../../utils/arrayUtils.js';
import { getCountMap } from '../../utils/getCountMap.js';
import { getPositionValuesPrecision } from '../../utils/getPositionPrecision.js';
import { ControlValues, getControlValues } from './getControlValues.js';


export const NUM_SPC_CHECKS = 7 as const;
export const MIN_POINTS_FOR_SPC = 10;

export const SPC_DESCRIPTIONS = [
    'One point beyond the tolerance limit',
    'One point beyond the 3σ control limit',
    'Eight or more points on one side of the centerline without crossing',
    'Four out of five points beyond 1σ',
    'Six points or more in a row steadily increasing or decreasing',
    'Two out of three points beyond 2σ',
    '14 points in a row alternating up and down',
];


export interface SpcCheckResult {
    value: string,
    checkResults: SpcChecksArray,
    hasIssues: boolean | null,
}

export type SpcChecksArray = FixedLengthArray<boolean | null, typeof NUM_SPC_CHECKS>;


export function spcCheckPosition(
    values: Array<string>,
    position: Position,
    enabledSpcChecks: boolean[],
): SpcCheckResult[] {
    if (position.type !== 'measurement') {
        throw new Error('SPC checks can only be performed on positions of type "measurement"');
    }

    const limits = getControlValues(values, position);
    if (!limits) {
        return values.map(value => ({
            value,
            checkResults: Array(NUM_SPC_CHECKS).fill(null) as SpcChecksArray,
            hasIssues: false,
        }));
    }

    const numberValues = values.map(it => parseFloat(it));
    const precision = getPositionValuesPrecision(values);
    const checks = [
        enabledSpcChecks[0] && values.length > 0 ? checkToleranceLimits(numberValues, limits) : Array(values.length).fill(null),
        enabledSpcChecks[1] && values.length > 9 ? checkControlLimits(numberValues, limits) : Array(values.length).fill(null),
        enabledSpcChecks[2] && values.length > 9 ? checkOnOneSideOfCenter(numberValues, limits, precision) : Array(values.length).fill(null),
        enabledSpcChecks[3] && values.length > 9 ? checkBZoneLimit(numberValues, limits) : Array(values.length).fill(null),
        enabledSpcChecks[4] && values.length > 9 ? checkConsecutiveChange(numberValues, limits) : Array(values.length).fill(null),
        enabledSpcChecks[5] && values.length > 9 ? checkAZoneLimit(numberValues, limits) : Array(values.length).fill(null),
        enabledSpcChecks[6] && values.length > 9 ? checkAlternatingPattern(numberValues) : Array(values.length).fill(null),
    ];
    if (checks.length !== NUM_SPC_CHECKS) throw new Error('Invalid number of spc checks');

    const checksTransposed = transpose(checks) as SpcChecksArray[];
    const result = values.map((value, i) => ({
        value,
        checkResults: checksTransposed[i],
        hasIssues: checksTransposed[i].some(it => it === true),
    }));
    return result;
}


//One point beyond the tolerance limit
function checkToleranceLimits(values: number[], limits: ControlValues): Array<boolean | null> {
    const flags = values.map(it => {
        if (it === null) return null;
        return it < limits.tolerances[0] || it > limits.tolerances[1];
    });
    return flags;
}

//One point beyond the 3 σ control limit
function checkControlLimits(values: number[], limits: ControlValues): Array<boolean | null> {
    const flags = values.map(it => {
        if (it === null) return null;
        return it < limits.sigma3[0] || it > limits.sigma3[1];
    });
    return flags;
}

//Eight or more points on one side of the centerline without crossing
function checkOnOneSideOfCenter(
    values: number[],
    limits: ControlValues,
    precision: number,
): Array<boolean | null> {
    const NUM_CONSECUTIVE = 8;
    const measurementErrorDiff = 0.1 ** precision * 0.55;

    const valueCounts = getCountMap(values, it => it);
    const mostCommonEntry = maxBy(Array.from(valueCounts.entries()), it => it[1]) ?? [null, 0];
    const extraCommonValue = mostCommonEntry[1] > values.length * 0.6 && mostCommonEntry[1] > 5 ? mostCommonEntry[0] : null;

    const flags = values.map(it => it == null ? null : false);
    let sequenceStart = 0;
    let countOnSameSide = 0;
    let lastDirectionAbove = null; // true if above, false if below, null if not yet determined
    for (let i = 0; i < values.length; i++) {
        if (values[i] == null) continue;

        const isOnCenterLine = Math.abs(values[i] - limits.mean) < measurementErrorDiff;
        if (isOnCenterLine || (extraCommonValue != null && values[i] === extraCommonValue)) {
            lastDirectionAbove = null;
            countOnSameSide = 0;
            continue;
        }

        const currentDirectionAbove = values[i] > limits.mean;
        if (currentDirectionAbove === lastDirectionAbove) {
            countOnSameSide++;
        } else {
            countOnSameSide = 1;
            sequenceStart = i;
            lastDirectionAbove = currentDirectionAbove;
        }

        if (countOnSameSide >= NUM_CONSECUTIVE) {
            setFlagsTrue(flags, sequenceStart, i + 1);
        }
    }
    return flags;
}


//Four out of five points in zone B or beyond
function checkBZoneLimit(values: number[], limits: ControlValues): Array<boolean | null> {
    const flags = flagSequenceByLimits(
        values,
        limits.sigma1, //zone B begins after zone C ends
        4,
        true
    );
    // if (flags) console.log('triggered: checkBZoneLimit');
    return flags;
}

//Six points or more in a row steadily increasing or decreasing
function checkConsecutiveChange(values: number[], limits: ControlValues): Array<boolean | null> {
    const NUM_CONSECUTIVE = 6;

    const flags = values.map(it => it == null ? null : false);
    let increasingCount = 1;
    let decreasingCount = 1;
    for (let i = 1; i < values.length; i++) {
        if (values[i] === null) continue;
        if (values[i] > values[i - 1]) {
            increasingCount++;
            decreasingCount = 1;
        } else if (values[i] < values[i - 1]) {
            decreasingCount++;
            increasingCount = 1;
        } else {
            increasingCount = 1;
            decreasingCount = 1;
        }

        if (increasingCount >= NUM_CONSECUTIVE || decreasingCount >= NUM_CONSECUTIVE) {
            setFlagsTrue(flags, i - NUM_CONSECUTIVE + 1, i + 1);
            // console.log('triggered: checkConsecutiveChange');
        }
    }
    return flags;
}


//Two out of three points in zone A
function checkAZoneLimit(values: number[], limits: ControlValues): Array<boolean | null> {
    const flags = flagSequenceByLimits(
        values,
        limits.sigma2, //zone A begins after zone B ends
        2,
        true
    );
    // if (flags) console.log('triggered: checkAZoneLimit');
    return flags;
}

//14 points in a row alternating up and down
function checkAlternatingPattern(values: number[]): Array<boolean | null> {
    const NUM_CONSECUTIVE = 14;
    let sequenceStart = 0;
    let countAlternating = 1;
    let lastDirectionUp: boolean | null = null; // true if up, false if down, null for the first comparison

    const flags = values.map(it => it == null ? null : false);
    for (let i = 1; i < values.length; i++) {
        if (values[i] === null) continue;

        const delta = values[i] - values[i - 1];
        if (delta === 0) {
            countAlternating = 0;
            lastDirectionUp = null;
            continue;
        }

        const currentChangeUp = delta > 0;
        if (lastDirectionUp !== null && currentChangeUp !== lastDirectionUp) {
            countAlternating++;
        } else {
            countAlternating = 2; // Reset to 2 to include the current and the previous point
            sequenceStart = i - 1;
        }

        lastDirectionUp = currentChangeUp;

        if (countAlternating >= NUM_CONSECUTIVE) {
            setFlagsTrue(flags, sequenceStart, i + 1);
            // console.log('triggered: checkAlternatingPattern');
        }
    }
    return flags;
}


function flagSequenceByLimits(
    values: number[],
    limits: [number, number],
    requiredCount: number,
    allowOneSkip: boolean
): Array<boolean | null> {
    const flags = values.map(it => it === null ? null : false);

    for (let startIdx = 0; startIdx < values.length - requiredCount + 1; startIdx++) {

        let outsideCount = 0;
        let skipUsed = false;
        for (let currentIdx = startIdx; currentIdx < values.length; currentIdx++) {
            if (values[currentIdx] === null) continue;

            const currentValue = values[currentIdx];
            const isOutsideLimits = currentValue > limits[1] || currentValue < limits[0];
            if (isOutsideLimits) {
                outsideCount++;
                if (outsideCount >= requiredCount) {
                    setFlagsTrue(flags, startIdx, currentIdx + 1);
                }
            } else if (allowOneSkip && !skipUsed && currentIdx != startIdx) {
                skipUsed = true;
            } else {
                break;
            }

        }
    }
    return flags;
}

function setFlagsTrue(
    flags: Array<boolean | null>,
    startIdx: number,
    endIdxExclusive: number
) {
    if (endIdxExclusive < startIdx) return;
    for (let i = startIdx; i < endIdxExclusive; i++) {
        flags[i] = true;
    }
}

function transpose<T>(matrix: T[][]): T[][] {
    return matrix[0].map((_, colIndex) => matrix.map(row => row[colIndex]));
}


export const testExports = {
    checkToleranceLimits,
    checkControlLimits,
    checkOnOneSideOfCenter,
    checkBZoneLimit,
    checkConsecutiveChange,
    checkAZoneLimit,
    checkAlternatingPattern,
}
