import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { SpcState } from '../../../shared/types/spcState';
import { fetchSpcStates } from '../api/requests';
import NavBar from '../components/navBar';


export default function SpcList() {

    const { data: spcStates, error, isLoading } = useQuery({
        queryKey: ['spc/current'],
        queryFn: async () => await fetchSpcStates(),
    });


    return (
        <>
            <NavBar />
            <div className='content' style={{ maxWidth: '1280px' }}>
                {isLoading && <div>Loading...</div>}
                {error && <div>Error: {error.message}</div>}
                {spcStates && <SpcTable spcStates={spcStates} />}
            </div>
        </>
    );
}


function SpcTable({
    spcStates,
}: {
    spcStates: SpcState[]
}) {
    return (
        <table>
            <thead>
                <tr>
                    <th>Equipment</th>
                    <th>Part number</th>
                    <th>Order number</th>
                    <th style={{ maxWidth: '70px' }}>Measurements<br />order / batch</th>
                    <th style={{ maxWidth: '70px' }}>Issues<br />enabled / all</th>
                    <th />
                </tr>
            </thead>
            <tbody>
                {spcStates.map(spcState => (
                    <tr key={spcState.equipment}>
                        <td>{spcState.equipmentDisplayName}</td>
                        <td>{spcState.partDisplayName}</td>
                        <td>{spcState.orderNumber}</td>
                        <td>{spcState.measurementsInOrder} / {spcState.measurementsInBatch}</td>
                        <td>{spcState.enabledIssuesCount} / {spcState.allIssuesCount}</td>
                        <td><Link to={`/spc-details?orderNumber=${spcState.orderNumber}`} className='a__button'>View</Link></td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
