import { v4 as uuidv4 } from 'uuid';
import { RecordPosition } from '../../../shared/types/record';
import { ProtocolDraft, ProtocolFinal, RecordDraft, RecordFinal } from '../types/sharedTypeImpl';


export function protocolToDraft(protocol: ProtocolFinal): ProtocolDraft {
    return {
        ...protocol,
        positions: protocol.positions.map(position => ({
            ...position,
            key: uuidv4(),
        })),
    }
}

export function draftToProtocol(draft: ProtocolDraft): ProtocolFinal {
    const protocol = structuredClone(draft);
    protocol.positions.forEach(position => {
        delete position.key;
        delete position.imageFile;
    });
    return protocol;
}


export function recordToDraft(record: RecordFinal): RecordDraft {
    return {
        //App controlled
        _id: record._id,
        isDraft: true,
        protocolId: record.protocolId,
        protocolPartDisplayName: record.protocolPartDisplayName,
        protocolVersion: record.protocolVersion,
        equipment: record.equipment,
        equipmentDisplayName: record.equipmentDisplayName,
        product: record.product,
        sessionStart: record.sessionStart,
        measurementId: record.measurementId,
        measurementStart: record.measurementStart,
        measurementEnd: record.measurementEnd,

        //User controlled
        orderNumber: record.orderNumber ?? '',
        targetQuantity: record.targetQuantity?.toString() ?? '',
        hasTargetQuantityChanged: false,
        partCount: record.partCount?.toString() ?? '',
        isPartCountEditEnabled: false,
        usedTools: record.usedTools ?? [],
        positionValues: record.positionValues.map(it => positionValueToFModel(it)),
        generalVisualInspections: record.generalVisualInspections,
        user: record.user ?? '',
        comment: record.comment ?? '',
    }
}

export function newRecordDraft(
    protocol: ProtocolFinal,
    equipment: string,
    equipmentDisplayName: string,
    product: string | null,
    sessionStart: Date | null,
    measurementId: string,
    measurementStart: Date,
    partCount: number | null,
): RecordDraft {
    return {
        isDraft: true,
        protocolId: protocol._id,
        protocolPartDisplayName: protocol.partDisplayName,
        protocolVersion: protocol.version!,
        equipment,
        equipmentDisplayName,
        product,
        sessionStart,
        measurementId,
        measurementStart,
        orderNumber: '',
        targetQuantity: '',
        hasTargetQuantityChanged: false,
        partCount: partCount?.toString() ?? '',
        isPartCountEditEnabled: partCount == null,
        usedTools: [],
        user: '',
        comment: '',
        positionValues: protocol.positions.map(it => ({ value: '' })),
    }
}


export function draftToRecord(draft: RecordDraft, numIssues: number): RecordFinal {
    return {
        _id: draft._id,
        isDraft: false,
        protocolId: draft.protocolId,
        protocolPartDisplayName: draft.protocolPartDisplayName,
        protocolVersion: draft.protocolVersion,
        equipment: draft.equipment,
        equipmentDisplayName: draft.equipmentDisplayName,
        product: draft.product,
        sessionStart: draft.sessionStart,
        measurementId: draft.measurementId,
        measurementStart: draft.measurementStart,
        measurementEnd: draft.measurementEnd,
        numIssues,
        orderNumber: draft.orderNumber,
        targetQuantity: draft.targetQuantity ? +draft.targetQuantity : null,
        partCount: draft.partCount ? +draft.partCount : null,
        usedTools: draft.usedTools,
        positionValues: draft.positionValues.map(it => fModelToPositionValue(it)),
        generalVisualInspections: draft.generalVisualInspections,
        user: draft.user,
        comment: draft.comment,
    }
}


export function positionValueToFModel(positionValue: RecordPosition): RecordPosition {
    return {
        // type: positionValue.type,
        value: positionValue.value,
        source: positionValue.source,
        sourceSubType: positionValue.sourceSubType,
    }
}

export function fModelToPositionValue(positionValueFModel: RecordPosition): RecordPosition {
    return {
        // type: positionValueFModel.type,
        value: positionValueFModel.value === '' ? null : positionValueFModel.value,
        source: positionValueFModel.source,
        sourceSubType: positionValueFModel.sourceSubType,
    }
}
