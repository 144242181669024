import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { fetchIsAdmin, logout } from '../api/requests';
import useFetchOnce from '../hooks/useFetchOnce';


export default function NavBar() {
    const { data: isAdmin } = useFetchOnce<boolean>({
        queryKey: ['isAdmin'],
        queryFn: async () => await fetchIsAdmin(),
    });

    return (
        <nav>
            <ul className="nav">
                <Internal href='/' text='Home' />
                <Internal href='/measurement-interval' text='Measurement' />
                <Internal href='/machine-log' text='Machine Log' />
                <Internal href='/overlook' text='Overlook' />
                <Internal href='/potential-scraps' text='Scraps' />
                <Internal href='/management-view' text='Management View' />
                <Internal href='/live-graphs' text='Live graphs' />
                <Internal href='/broken-tools' text='Broken Tools' />
                {isAdmin && <Internal href='/protocol-list' text='Protocols' />}
                <Internal href='/record-list' text='Records' />
                {isAdmin && <Internal href='/operators' text='Operators' />}
                {isAdmin && <Internal href='/spc-list' text='Spc' />}
                {isAdmin && <Internal href='/reporting-tool' text='Reporting' />}
                <External href='http://80.246.157.14:3000/' text='MT-LINKi' />
                <External href='https://berryglade.sharepoint.com/sites/BGNET' text='BGNET' />
                <NavSpacer />
                <External href='https://berryglade.sharepoint.com/:r:/s/BGNET/EQqUpUnfYEtHkUFOiuILpRMB6anZRrzgtYlsfgLW8i4xtg?e=5Xycay' text='Help' openInNewTab={true} />
                <Logout />
            </ul>
        </nav>
    );
}


interface InternalProps {
    href: string;
    text: string;
}

function Internal({
    href,
    text,
}: InternalProps) {

    return (
        <li className='nav__item'>
            <NavLink
                to={href}
                className={({ isActive }) => classNames('nav__link', { 'nav__link--active': isActive })}
            >
                {text}
            </NavLink>
        </li>
    );
}


interface ExternalProps {
    href: string;
    text: string;
    openInNewTab?: boolean;
}
function External({
    href,
    text,
    openInNewTab = false,
}: ExternalProps) {

    return (
        <li className='nav__item'>
            <a
                className={'nav__link'}
                href={href}
                target={openInNewTab ? '_blank' : '_self'}
            >
                {text}
            </a>
        </li>
    );
}


function Logout() {
    const handleLogout = async (event: React.MouseEvent) => {
        event.preventDefault();
        const redirectUrl = await logout();
        window.location.href = redirectUrl;
    };

    return (
        <li className='nav__item'>
            <a
                href='#'
                className={'nav__link'}
                onClick={handleLogout}
            >
                Sign out
            </a>
        </li>
    );
}


function NavSpacer() {
    return (
        <li className='flex__spacer' />
    );
}
