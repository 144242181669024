import { useMutation } from '@tanstack/react-query';
import { OperationRequestOptions } from '../../../../shared/types/operation';
import { postOperation, updateOperation } from '../../api/requests';
import { NewOperation, Operation, Session } from '../../types/sharedTypeImpl';
import { logError } from '../../utils/errorLogging';
import ModalDialog from '../modalDialog';
import { OperationFormResult } from './operationDraft';
import OperationForm from './operationForm';


interface OperationDialogProps {
    isOpen: boolean;
    session?: Session | null;
    otherOperations?: Operation[];
    operationUnderEdit?: Operation | null;
    onClose: () => void;
    onOperationSaved: (operation: Operation) => void;
}

interface MutationParams {
    operation: NewOperation | Operation;
    options: OperationRequestOptions;
}


export default function OperationDialog({
    isOpen,
    session,
    otherOperations,
    operationUnderEdit,
    onClose,
    onOperationSaved,
}: OperationDialogProps) {

    const operationSave = useMutation({
        mutationFn: async (params: MutationParams) =>
            (params.operation as any)._id
                ? await updateOperation(params.operation as Operation, params.options)
                : await postOperation(params.operation as NewOperation, params.options),
        onSuccess: (savedOperation: Operation) => {
            onOperationSaved(savedOperation);
            onClose();
        }
    });

    const handleOperationSubmit = (operationFormResult: OperationFormResult) => {
        const newOperation: NewOperation | Operation = {
            equipment: session?.equipment,
            product: session?.product,
            ...operationFormResult,
            ...(operationUnderEdit && {
                _id: operationUnderEdit._id,
                equipment: operationUnderEdit.equipment,
                product: operationUnderEdit.product,
                group: operationUnderEdit.group,
                time: operationUnderEdit.time,
                partCount: operationUnderEdit.partCount,
                scrapLabel: operationUnderEdit.scrapLabel,
                cycleTimeOverall: operationUnderEdit.cycleTimeOverall,
                cycleTimeRecent: operationUnderEdit.cycleTimeRecent,
            }),
        };

        const createScrapLabel = operationUnderEdit == null && newOperation.potentialDiscards > 0
            || operationUnderEdit && !operationUnderEdit.potentialDiscards && newOperation.potentialDiscards > 0;
        const releaseScrapLabel = operationUnderEdit?.potentialDiscards && !newOperation.potentialDiscards;

        operationSave.mutate({
            operation: newOperation,
            options: {
                createScrapLabel,
                releaseScrapLabel,
            }
        });
    }



    if (!isOpen) return null;
    if (!session && !operationUnderEdit) {
        logError('Session is required for new operations');
        return null;
    }

    return (
        <ModalDialog
            isOpen={isOpen}
            onCancel={onClose}
            frameClassName='modal-frame--operation-dialog'
            contentClassName='modal-content--tablet'
        >
            <OperationForm
                session={session}
                operationUnderEdit={operationUnderEdit}
                otherOperations={otherOperations}
                onOperationSubmit={handleOperationSubmit}
                isSaving={operationSave.isPending}
            />
        </ModalDialog>
    )
}
