import { countSpcIssues } from '../../../../shared/services/spc/countSpcIssues';
import { Protocol, RecordWithProtocol } from '../../types/sharedTypeImpl';


interface UseNumIssuesProps {
    protocol: Protocol | null;
    records: RecordWithProtocol[] | null;
    enabledSpcChecks: Map<string, boolean[]>;
    numLatest: number | undefined;
}

export function useNumIssues({
    protocol,
    records,
    enabledSpcChecks,
    numLatest,
}: UseNumIssuesProps) {

    if (protocol && records && enabledSpcChecks) {
        return countSpcIssues(protocol, records, enabledSpcChecks, numLatest);
    } else {
        return null;
    }
}
