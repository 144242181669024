import { getWeekNumber } from '../../../../shared/utils/dateUtils';
import { OeeRow } from '../../types/sharedTypeImpl';
import { OeeGraphOptions } from '../oee-graph-controls/oeeGraphOptios';
import { NUM_WEEKS } from './numWeeks';


export interface TransformedData {
    start: Date;
    end: Date;
    weekNumber: number;
    percentage: number;
    hours: number;
}


export function transformData(
    oeeData: OeeRow[],
    oeeGraphOptions: OeeGraphOptions,
): TransformedData[] {
    const startOfWeek = getStartOfCurrentWeek();

    const HOUR = 60 * 60 * 1000;
    const WEEK = 7 * 24 * 60 * 60 * 1000;
    const startOfData = new Date(+startOfWeek - 5 * WEEK - 12 * HOUR);

    const oeeDataFiltered = oeeData
        .filter(it => oeeGraphOptions.visibleShifts.includes(it.shift))
        .filter(it => !oeeGraphOptions.excludeWeekends || it.shiftName !== 'W');

    const aggregatedPerWeek: TransformedData[] = [];
    for (let i = 0; i < NUM_WEEKS; i++) {
        const startLimit1 = new Date(+startOfData + i * WEEK);
        const startLimit2 = new Date(+startLimit1 + WEEK);
        const oeeDataForWeek = oeeDataFiltered.filter(it => it.start >= startLimit1 && it.start < startLimit2);

        const aggregated = aggregateAvailability(oeeDataForWeek);
        aggregatedPerWeek.push({
            start: startLimit1,
            end: startLimit2,
            weekNumber: getWeekNumber(new Date(+startLimit1 + 0.5 * WEEK)),
            ...aggregated,
        });
    }
    return aggregatedPerWeek;
}

function getStartOfCurrentWeek(date: Date = new Date()): Date {
    const dayOfWeek = (date.getDay() + 6) % 7; // Adjust Sunday (0) to be the last day
    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - dayOfWeek); // Go back to Monday
    startOfWeek.setHours(0, 0, 0, 0); // Reset time to midnight
    return startOfWeek;
}

function aggregateAvailability(oeeData: OeeRow[]): {
    percentage: number;
    hours: number;
} {
    const totalProductionTime = oeeData.reduce((acc, it) => acc + it.productionTime, 0);
    const totalStopTime = oeeData.reduce((acc, it) => acc + it.stopTime, 0);

    const percentage = (totalProductionTime + totalStopTime === 0)
        ? 0
        : totalProductionTime / (totalProductionTime + totalStopTime);
    const hours = totalProductionTime / 3600_000;

    return {
        percentage,
        hours
    };
}
