import { useEffect, useMemo } from 'react';
import { RecordWithProtocol } from '../../types/sharedTypeImpl';


export interface RangeOption {
    key: string,
    count: number | null,
    label: string,
}

interface RangeSelectionProps {
    style?: React.CSSProperties,
    orderNumber: string,
    batchRecords: RecordWithProtocol[],
    selectedRange: RangeOption | null,
    setSelectedRange: (selectedRange: RangeOption) => void,
}

export default function RangeSelection({
    style,
    orderNumber,
    batchRecords,
    selectedRange,
    setSelectedRange,
}: RangeSelectionProps) {

    const options: RangeOption[] = useMemo(() => {
        const numInBatch = batchRecords.length;
        const numWithOrderNumber = batchRecords.filter(it => it.orderNumber === orderNumber).length;

        return [
            { key: 'latest_10', count: 10, label: 'Latest 10' },
            { key: 'latest_20', count: 20, label: 'Latest 20' },
            { key: 'latest_30', count: 30, label: 'Latest 30' },
            { key: 'orderNumber', count: numWithOrderNumber, label: `Order number (${numWithOrderNumber})` },
            { key: 'batch', count: numInBatch, label: `Batch (${numInBatch})` },
        ]
    }, [orderNumber, batchRecords]);

    useEffect(() => {
        if (selectedRange === null) {
            setSelectedRange(options.find(it => it.key === 'orderNumber'));
        }
    }, [selectedRange, setSelectedRange, options]);


    return (
        <div style={{ ...style, display: 'flex', flexDirection: 'column' }}>
            {options.map((option) => (
                <label
                    key={option.key}
                    style={{ color: option.count > batchRecords.length ? 'gray' : '' }}
                >
                    <input
                        type='radio'
                        name='range'
                        value={option.key}
                        checked={option.key === selectedRange?.key}
                        disabled={option.count > batchRecords.length}
                        onChange={() => setSelectedRange(option)}
                    />
                    {option.label}
                </label>
            ))}
        </div>
    );
}
