import { Position } from '../types/protocol.js';
import { getPositionPrecision } from './getPositionPrecision.js';
import { roundToPrecision } from './mathUtils.js';


export function getToleranceRange(position: Position): { min: number, max: number } {
    const precision = getPositionPrecision(position);
    const min = roundToPrecision(+position.nominal! + +position.lowerTolerance!, precision);
    const max = roundToPrecision(+position.nominal! + +position.upperTolerance!, precision);
    return { min, max };
}

export function getToleranceRangeStrings(position: Position): { min: string, max: string } {
    const precision = getPositionPrecision(position);
    const { min, max } = getToleranceRange(position);
    return {
        min: min.toFixed(precision),
        max: max.toFixed(precision),
    }
}
