import { forwardRef, useImperativeHandle } from 'react';
import OperatorSelect from '../operatorSelect';
import { OpSubFormProps, OpSubFormRef } from './operationSubForm';


export const OperatorSubForm = forwardRef<OpSubFormRef, OpSubFormProps>(({
    operation,
    onOperationChange,
}: OpSubFormProps, ref) => {

    useImperativeHandle(ref, () => ({
        validateWarnAndGet: (result) => {
            if (!operation.operator) {
                alert('Operator is required');
                return false;
            }
            result.operator = operation.operator;
            return true;
        }
    }));

    return (
        <div className='dialog-form__field--with-margins'>
            <label>Operator</label>
            <OperatorSelect
                className='dialog-form__input'
                operatorInitials={operation.operator ?? ''}
                onChange={(initials) => onOperationChange({ ...operation, operator: initials })}
            />
        </div>
    );
});
